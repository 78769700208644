<template>
  <div class="h-full bg-white pl-7 w-full">
    <PageHeader :title="$t('properties.title')" :breadcrumbs="breadcrumbs" />
    <!--    <Breadchrumbs class="mt-2" :breadcrumbs="breadcrumbs"/>-->
    <!--    <p class="header-component mt-4"> {{ $t('properties.title') }}</p>-->
    <!--    <hr class=" hr-line-user  mr-7 mb-1 mt-8 "/>-->
    <ModalDialog
      v-show="isModalPdf"
      @close="handleModalPdf"
      :showClose="true"
      headerText="Pdf"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start mt-3 mr-4 ml-4 space-y-4">
          <img class="rect-img" :src="link_file" />
          <div class="flex justify-end space-x-10 mt-2 mr-6 w-full">
            <div class="flex mt-2 mb-6 justify-end">
              <Button
                class=""
                :secondary="true"
                @click="handleModalPdf"
                :text="$t('buttons.cancel')"
              >
              </Button>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>
    <div class="flex flex-row w-full">
      <div class="mr-7 w-full" v-if="!showUpload">
        <div class="flex flex-col w-full">
          <label class="text mt-12 mb-8"
            >{{ $t("properties.add_options_lbl") }}
            <span style="color: red">*</span></label
          >

          <div class="flex flex-col space-y-5">
            <div
              class="flex justify-start items-center cursor-pointer"
              @click="createOptionSelected('manual')"
            >
              <RadioButton
                type="radio"
                v-model="radio"
                name="radio-button"
                value="manual"
                checked
              />
              <span class="ml-3 textradio">{{
                $t("properties.add_options.manual")
              }}</span>
            </div>
            <div
              class="flex justify-start items-center cursor-pointer"
              @click="createOptionSelected('csvimport')"
            >
              <RadioButton
                type="radio"
                name="radio-button"
                v-model="radio"
                value="csvimport"
              />
              <span class="ml-3 textradio">{{
                $t("properties.add_options.csv_import")
              }}</span>
            </div>
            <div
              class="flex justify-start items-center cursor-pointer"
              @click="createOptionSelected('bdo_grizzly_import')"
            >
              <RadioButton
                type="radio"
                name="radio-button"
                v-model="radio"
                value="bdo_grizzly_import"
              />
              <span class="ml-3 textradio">{{
                $t("properties.add_options.bdo_grizzly_import")
              }}</span>
            </div>
            <div
              class="flex justify-start items-center cursor-pointer"
              @click="createOptionSelected('upload_annex_v')"
              v-if=" false && !is_tax_office_software_datev"
            >
              <RadioButton
                type="radio"
                name="radio-button"
                v-model="radio"
                value="upload_annex_v"
              />
              <span class="ml-3 textradio">{{
                $t("properties.add_options.upload_annex_v")
              }}</span>
            </div>
            <div
              class="flex justify-start items-center cursor-pointer"
              @click="createOptionSelected('upload_annex_luf')"
              v-if="false && !is_tax_office_software_datev"
            >
              <RadioButton
                type="radio"
                name="radio-button"
                v-model="radio"
                value="upload_annex_luf"
              />
              <span class="ml-3 textradio">{{
                $t("properties.add_options.upload_annex_luf")
              }}</span>
            </div>
            <div
              class="flex justify-start items-center cursor-pointer"
              @click="createOptionSelected('upload_annex_datev')"
              v-if="is_tax_office_software_datev"
            >
              <RadioButton
                type="radio"
                name="radio-button"
                v-model="radio"
                value="upload_annex_datev"
              />
              <span class="ml-3 textradio">{{
                $t("properties.add_options.upload_annex_datev")
              }}</span>
            </div>
            <div class="flex justify-start items-center cursor-pointer" @click="createOptionSelected('client_invitation')"
                 v-show="!$isBusiness">
              <RadioButton type="radio" name="radio-button" v-model="radio" value="client_invitation"/>
              <span class="ml-3 textradio">{{ $t('properties.add_options.request_client_portal') }}</span>
            </div>
            <div class="flex justify-start items-center mr-6 cursor-pointer"
                 v-show="isAddisonEnabled"
                 @click="createOptionSelected('addison')">
              <RadioButton v-model="radio" name="radio-button" value="addison"
                           @click="createOptionSelected"/>
              <span class="ml-4 mr-2 textradio cursor-pointer">{{ $t('clients.menu.addison_import') }}</span>
              <img class="help" :src="getAssetPath('help_green.svg')"
                   v-tooltip.right="$t('clients.add.addison_import_property')"/>
            </div>
            <div class="flex justify-start items-center mr-6 cursor-pointer"
                 v-show="isLexwareEnabled"
                 @click="createOptionSelected('lexware')">
              <RadioButton v-model="radio" name="radio-button" value="lexware"
                           @click="createOptionSelected"/>
              <span class="ml-3 mr-2 textradio cursor-pointer">{{ $t('clients.lexware_import.name') }}</span>
            </div>
          </div>
        </div>
        <div class="flex mt-10 mb-10 justify-end space-x-4">
          <Button
            class="w-56"
            :text="$t('buttons.cancel')"
            :secondary="true"
            @click="cancelAdd"
          />
          <Button
            class="w-56 aaaa"
            :text="$t('buttons.next')"
            @click="submitNext"
          />
        </div>
      </div>
      <!--      Upload Section-->
      <div class="flex flex-col w-full mr-7" v-else>
        <div class="mr-7 w-full flex flex-col justify-start" v-if="!finish">
          <div class="flex flex-col">
            <label class="title text-left mt-4">{{ title }}</label>
            <input
              :accept="accept_file_upload"
              type="file"
              id="file_input_upload_doc"
              @change="fileUploadEvent($event)"
              class="opacity-0 hidden"
              name="file_input_upload_doc"
              multiple
              ref="file_input"
            />
            <div
              class="flex justify-around my-6 space-x-10"
              v-if="radio === 'upload_annex_luf' || radio === 'upload_annex_datev'"
            >
              <InputSelect
                class="w-full"
                v-model="clientUid"
                :selected-value="clientUid"
                :isError="isClientError"
                :errorMessage="clientError"
                :label="$t('properties.step1.client')"
                :total-record-count="totalClientsCount"
                :sort="true"
                :search-fn="clientListSearch"
                :options="clientList"
                :filterable="true"
                :isRequired="true"
                placeholder=""
                @on-item-selected="selectClientUid"
              />

              <InputSelect
                class="w-full"
                v-model="assignedUid"
                :selected-value="assignedUid"
                :isError="isAssigneeError"
                :errorMessage="assigneeError"
                :label="$t('declarations.add_declaration.steps.step1.assignedUser')"
                :total-record-count="totalClientsCount"
                :sort="true"
                :options="userList"
                :filterable="true"
                :isRequired="true"
                placeholder=""
                @on-item-selected="selectAssignedUid"
              />

              <div class="w-full pr-6 invisible" />
            </div>
            <div
              class="box flex-col flex h-auto"
              v-cloak
              @drop.prevent="addFiles"
              @dragover.prevent
            >
              <!--              //v-show="files.length===0"-->
              <div
                class="flex flex-col mt-8 justify-center items-center"
                v-show="!showFiles"
              >
                <img class="img" src="@/assets/docs_gray.svg" />
                <label
                  class="label w-96 mt-4"
                  v-html="this.is_tax_office_software_datev
                    ? $t('properties.document_upload.drop_files_datev', {option_file_type: getTextDescription})
                    : $t('properties.document_upload.drop_files', {option_file_type: getTextDescription})"
                ></label>
                <Button
                  class="mt-4 mb-4 w-52"
                  text="Dateien wählen"
                  @click="canShowFiles"
                />
              </div>

              <table
                class="filelist divide-y divide-gray m-4"
                v-show="showFiles"
              >
                <div
                  class="space-y-4 item"
                  v-for="(file, index) in files"
                  :key="index"
                  :style="{
                    'background-color': file.success ? '#eeffef' : '#fff8f1',
                    display: 'inherit',
                  }"
                >
                  <tr>
                    <td
                      class="filename py-5 ml-3 pl-1"
                      :title="file.file_name || file.name"
                    >
                      {{ file.file_name || file.name }}
                    </td>
                    <td class="w-full">
                      <div class="flex justify-between items-center">
                        <div
                          class="flex justify-start items-center space-x-4 ml-4"
                        >
                          <label
                            >({{
                              file.size || file.file_size | formatSize
                            }})</label
                          >
                          <img
                            class="upload-icon cursor-pointer"
                            :src="file.doc_img"
                            v-show="file.url && file.prim_uid && file.files.success"
                            @click="viewDocument(file.prim_uid)"
                          />



                          <label v-show="file.success">{{
                            file.address
                          }}</label>
                        </div>
                        <div
                          class="flex justify-end items-center mr-6 space-x-1"
                        >
                          <img
                            :src="getAssetPath('uploading.svg')"
                            class="uploading-icon"
                            v-if="file.isUploading"
                          />
                          <img
                            class="upload-icon"
                            :src="file.status_icon"
                            v-show="file.status_icon"
                            v-else
                          />
                          <label>{{ file.statusText }}</label>
                          <div
                            class="
                              btn-add-client
                              inline-flex
                              flex-col
                              items-start
                            "
                            v-if="file.isAddClient"
                          >
                            <a
                              class="link"
                              @click="handleAddClient(true, file)"
                              >{{ file.addPrivatperson }}</a
                            >
                            <!-- <a
                              class="link"
                              @click="handleAddClient(false, file)"
                              >{{ file.addFirma }}</a
                            > -->
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </div>
              </table>
            </div>
          </div>

          <div
            class="flex mt-10 mb-10 justify-end space-x-4"
            v-if="!isUploading"
          >
            <Button
              class="w-56"
              :text="$t('buttons.back')"
              :secondary="true"
              @click="goBackToProperty"
            />
            <Button
              class="w-56"
              :text="$t('buttons.next')"
              @click="submitNext"
              v-show="showButton"
            />
          </div>
        </div>
        <!--        finish-->
        <div class="w-full justify-center items-center" v-else>
          <div
            class="
              w-full
              flex flex-col
              justify-center
              items-center
              ml-15
              mr-15
              mt-20
            "
          >
            <div
              class="
                rounded-full
                flex
                items-center
                justify-center
                bg-green
                w-24
                h-24
                flex
                m-2
              "
            >
              <img src="@/assets/check_done.svg" />
            </div>
            <label class="text-finish"
              >Neues Grundstück wurde hinzugefügt.</label
            >
            <Button
              @click="handleFinish()"
              class="mt-10"
              :text="getLabelFinishButton"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import InputSelect from "../inputs/InputSelect";
import RadioButton from "primevue/radiobutton";
import {from} from "rxjs";
import {AppendixService} from "@/services/appendix.service";
import {ClientService} from "@/services/client.service";
import {UserService} from "@/services/user.service";
import * as _ from "lodash";


export default {
    name: "add",
    components: {InputSelect,RadioButton},

    data() {
        return {
            isModalPdf: false,
      title: "Anlage V hochladen",
      radio: "manual",
      totalClientsCount: 0,
      showFiles: false,
      showButton: true,
      finish: false,
      showUpload: false,
      files: [],
      parentType: "",
      parentId: 0,
      breadcrumbs: [
        {
          title: this.$t("breadcrumbs.land_units"),
            link: '/property/list'
        },
        {
          title: this.$t("breadcrumbs.add_property"),
          link: "",
        },
      ],
      errMsg: [],
      service: new AppendixService(),
      clientService: new ClientService(),
      link_file: null,
      is_tax_office_software_datev: false,
      accept_file_upload:
        ".pdf,.xls,.xlsl,.doc,.docx,.ppt,.pptx,.txt,.xml,.jpg,.jpeg,.png",
      isUploaded: false,
      // add option choose client
      clientList: [],
      clientUid: "",
      assignedUid: "",
      clientError: "",
      assigneeError: "",
      isClientError: false,
      isAssigneeError: false,
      isUploading: false,
      userList: [],
    };
  },
  mounted() {
    if (!(this.getCurrentUser().permissions.manage_property && parseInt(this.getCurrentUser().permissions.manage_property) === 1)) {
      this.$router.push('/property/list');
    }

    this.init();

    if (
      this.$route.params.parentType
      && this.$route.params.parentType === 'CLIENT'
      && this.$route.params.parentId
    ) {
      this.parentType = this.$route.params.parentType;
      this.parentId = this.$route.params.parentId;
      this.clientUid = this.$route.params.parentId + ''
    }

    if (this.getCurrentUser().settings.tax_office_software === 'datev') {
      this.is_tax_office_software_datev = true;
      this.accept_file_upload = '.rtf';
    }

    let userService = new UserService();
    userService.listAll().then(async (list) => {
      this.userList = list.reduce((filtered, item) => {
        if (item.statusKey === 'active' && (item.roleKey === 'administrator' || item.roleKey === 'user')) {
          filtered.push({
            name: item.name,
            code: item.id
          });
        }

        return filtered;
      }, []);
    });
  },
  destroyed() {
    this.isUploaded = false;
    this.isUploading = false;
  },
  computed: {
    getLabelFinishButton() {
      let text = "Grundstücke";
      if (
        this.files.length == 1 &&
        this.files[0].property_id &&
        this.isUploaded
      ) {
        text = "Grundstück bearbeiten";
      }
      return text;
    },

    getTextDescription() {
      let text = "Anlage V hochladen";
      switch (this.radio) {
        case "upload_annex_datev":
          text = "Datev Est";
          break;
        case "upload_annex_v":
          text = "Anlage V";
          break;
        case "upload_annex_luf":
          text = "Anlage LuF";
          break;
      }
      return text;
    },
    isAddisonEnabled() {
      if (this.getCurrentUser().settings.tax_office_software === 'addison-oneclick' && process.env.VUE_APP_ADDISON_IMPORT_ENABLED === 'true') {
        return true;
      }
      return false;
    },
    isLexwareEnabled() {
      if (this.getCurrentUser().settings.tax_office_software === 'lexware' && process.env.VUE_APP_LEXWARE_IMPORT_ENABLED === 'true') {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapMutations("property", ["init", "saveStepClient"]),
    ...mapGetters("user", ["getCurrentUser"]),
    getClients() {
      this.clientService.listUserClient("current").then(({list,totalRecordCount}) => {
        this.clientList = list ? list.map((o) => {
          return {
            name: o.client_id + " - " + o.client_name,
            code: o.prim_uid,
          };
        }) : [];
        this.totalClientsCount = totalRecordCount;
      });
    },
    selectClientUid(item) {
      if (item.code === '0' || item.code === '') {
        this.isClientError = true;
      } else {
        this.isClientError = false;
      }
    },
    selectAssignedUid(item) {
      if (item.code === '0' || item.code === '') {
        this.isAssigneeError = true;
      } else {
        this.isAssigneeError = false;
      }
    },
    clientListSearch(params) {

      return this.clientService.listUserClient('current', params).then((clients) => {
        if (clients && clients.list) {
          return clients.list.map((o) => {
            return {
              "name": o.client_id + ' - ' + o.client_name,
              "code": o.prim_uid,
              "street": o.street,
              "house_number": o.house_number,
              "zip_code": o.zip_code,
              "city": o.city
            };
          })
        } else {
          return false;
        }
      });
    },
    createOptionSelected(e) {
      this.radio = e;
    },
    viewDocument(id) {
      this.$router.push({
        name: "DocumentViewer",
        params: {
          id: id,
          parentType: this.parentType,
          parentId: this.parentId,
          isAppendix: true,
          isTaxOfficeSoftwareDatev: this.is_tax_office_software_datev ? 1 : 0,
        },
      });
    },
    editProperty(propertyId) {
      if (propertyId) {
        this.$router.push("/property/edit?uid=" + propertyId);
      }
    },
    handleModalPdf() {
      this.isModalPdf = false;
    },
    submitNext() {
      switch (this.radio) {
        case "csvimport":
          this.$router.push("/property/csvimportproperty");
          break;
        case 'bdo_grizzly_import':
          this.$router.push('/property/BdoGrizzlyImport');
          break;
        case "manual":
          this.$router.push({
            name: "Properties",
            params: {
              comp: "AddNewProperty",
              parentType: this.parentType,
              parentId: this.parentId,
            },
          });
          break;
        case "Mandant":
          this.$toast.info(this.$i18n.t("properties.extended_add_info"));
          break;
        case "upload_annex_datev":
          this.title = "Liste “Grundsteuerrelevante Daten” aus DATEV Est hochladen";
          if (!this.showUpload) {
            this.getClients();
            this.showUpload = true;
            this.showButton = false;
          } else if (!this.isUploaded) {
            this.$router.push("/property/list");
          } else {
            this.finish = true;
          }
          break;
        case "client_invitation":
          this.$router.push({
            name: "Properties",
            params: {
              comp: "mandant",
              parentType: this.parentType,
              parentId: this.parentId,
            },
          });
          break;
        case "upload_annex_v":
          // this.$toast.info(this.$i18n.t('properties.extended_add_info_march'));
          this.title = "Anlage V hochladen";
          if (!this.showUpload) {
            this.showUpload = true;
            this.showButton = false;
          } else if (!this.isUploaded) {
            this.$router.push("/property/list");
          } else {
            this.finish = true;
          }
          break;
        case "upload_annex_luf":
          // this.$toast.info(this.$i18n.t('properties.extended_add_info_march'));
          this.title = "Anlage LuF hochladen";
          if (!this.showUpload) {
            this.showUpload = true;
            this.showButton = false;
            this.getClients();
          } else if (!this.isUploaded) {
            this.$router.push("/property/list");
          } else {
            this.finish = true;
          }
          break;
        case "Mandant_form":
          this.$toast.info(this.$i18n.t("properties.extended_add_info"));
          break;
        case "addison":
          this.$router.push('/addison/AddisonImport')
          break;
        case "lexware":
          this.$router.push('/addison/LexwareImport')
          break;
      }
    },
    canShowFiles() {
      document.getElementById("file_input_upload_doc").click();
    },

    fileUploadEvent(e) {
      this.handleProcess(e.target.files);
    },

    handleProcess(files) {
      if (
        this.radio === 'upload_annex_luf'
        || this.radio === 'upload_annex_datev'
        && (
          (!this.clientUid || this.clientUid === '0')
          || (!this.assignedUid || this.assignedUid === '0')
        )
      ) {
        this.isClientError = true;
        this.isAssigneeError = true;
        //Resets file input, othersie onchange will not fire if user selects the same file again.
        this.$refs['file_input'].value = '';

        if ((!this.clientUid || this.clientUid === '0')) {
          this.clientError = this.$i18n.t('properties.document_upload.errors.noClient');
          this.$toast.error(this.$i18n.t('properties.document_upload.errors.noClient'));
        }

        if (!this.assignedUid || this.assignedUid === '0') {
          this.assigneeError = this.$i18n.t('properties.document_upload.errors.noAssignedUser');
          this.$toast.error(this.$i18n.t('properties.document_upload.errors.noAssignedUser'));
        }

        return;
      }

      if (files.length === 0) {
        this.$toast.error(this.$i18n.t('properties.document_upload.errors.noFileSelected'));
        return;
      }

      if (files.length > 10) {
        this.$toast.error(this.$i18n.t('properties.document_upload.errors.tooManyFiles'));
        return;
      }

      for (let i = 0; i < files.length; i++) {
        if (this.isLimitFileSize(files[i])) {
          this.$toast.error(
            this.$i18n.t("properties.document_upload.errors.fileTooBig", {file : files[i].name })
          );
          return;
        }
      }
      this.showFiles = true;
      this.showButton = true;
      this.files = Array.from(files);
      this.files.map((file) => {
        file.guid = this.generateUUID();
        file.isUploading = true;
        file.statusText = this.is_tax_office_software_datev
            ? this.$i18n.t("properties.document_upload.file_upload_status_datev")
            : this.$i18n.t("properties.document_upload.file_upload_status") ;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("clientUid", this.clientUid);
        formData.append("assignedUid", this.assignedUid);
        if (this.radio === "upload_annex_v") {
          formData.append("enum", "ESt");

        }
        if (this.radio === "upload_annex_luf") {
          formData.append("enum", "LuF");
        }

        this.isUploading = true;
        from(
          this.service.upload(formData, this.is_tax_office_software_datev)
        ).subscribe(
          (res) => {
            file.file_name = file.name;
            file.file_size = file.size;
            file = { ...file, ...res.data };
            let status_icon = null;
            let property_icon = null;
            let doc_img = null;
            if (res.data.success) {
              status_icon = this.getAssetPath("circle_tick.svg");
              property_icon = this.getAssetPath("edit-property.svg");
              doc_img = this.getAssetPath("upload_doc.svg");
              file = { ...file, ..._.get(res, "data.files[0]") };
              if (this.is_tax_office_software_datev) {
                file.property_id = _.get(file, "properties[0].property_id");
              }
              this.isUploaded = true;
            } else {
              switch (
                _.get(res, "data.errorKey") ||
                _.get(res, "data.error_key")
              ) {
                case 1:
                case 2:
                case 4:
                case 5:
                case 6:
                case 7:
                  status_icon = this.getAssetPath("warning.svg");
                  break;
                default:
                  break;
              }
            }
            file.property_icon = property_icon;
            file.status_icon = status_icon;
            file.doc_img = doc_img;
            // file.statusText = _.get(res, "data.message");
            file.statusText = this.getStatusText(res);
            file.isUploading = false;
            let idx = Array.from(this.files).findIndex(
              (item) => item.guid === file.guid
            );
            if (idx !== -1) {
              this.files[idx] = file;
              this.$forceUpdate();
            }
            if (idx === this.files.length - 1) {
              this.isUploading = false;
            }
          },
          (error) => {
            let idx = Array.from(this.files).findIndex(
              (item) => item.guid === file.guid
            );
            if (idx !== -1 && idx === this.files.length - 1) {
              this.isUploading = false;
            }
          }
        );
      });
    },

    getStatusText(res) {
      let ms = "";
      switch (_.get(res, "data.errorKey") || _.get(res, "data.error_key")) {
        case 1:
          ms = this.$i18n.t("properties.document_upload.errors.1") ;
          break;
        case 2:
          ms =  this.$i18n.t("properties.document_upload.errors.2") ;
          break;
        case 4:
          ms = this.$i18n.t("properties.document_upload.errors.4") ;
          break;
        case 5:
          ms = this.$i18n.t("properties.document_upload.errors.5") ;
          break;
        case 6:
          ms = this.$i18n.t("properties.document_upload.errors.fileTooBig") ;
          break;
        case 7:
          ms = this.$i18n.t("properties.document_upload.errors.7") ;
          break;
        case 3:
          ms = this.$i18n.t("properties.document_upload.errors.3") ;

      }
      return ms;
    },

    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime();
      if (
        typeof performance !== "undefined" &&
        typeof performance.now === "function"
      ) {
        d += performance.now(); //use high-precision timer if available
      }
      var newGuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );

      return newGuid;
    },

    isLimitFileSize({size}) {
      //1 MB 1024 * 1024 = 1048576
      let MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB
      if (size > MAX_FILE_SIZE) {
        return true;
      }
      return false;
    },

    cancelAdd() {
      this.showUpload = false;
      this.clientUid = "";
      this.goBack();
    },

    addFiles(e) {
      let droppedFiles = e.dataTransfer.files;
      let files = [];
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        files.push(f);
      });
      this.$emit("select", { originalEvent: event, files: this.files });
    },

    removeFile(file) {
      this.files = this.files.filter((f) => {
        return f !== file;
      });
      this.$emit("select", { originalEvent: event, files: this.files });
    },

    goBackToProperty() {
      this.clientUid = "";
      this.showUpload = false;
      this.showButton = true;
      this.showFiles = false;
    },

    async handleAddClient(isPrivate, file) {
      let data = {
        clientType: isPrivate ? "private" : "business",
        branch: 1,
        private: {
          first_name: file.first_name,
          last_name: file.last_name,
          tax_number: file.tax_number,
          salutation: file.salutation || "-",
          client_id: this.getRandomInt(),
        },
        business: {
          first_name: file.first_name,
          last_name: file.last_name,
          tax_number: file.tax_number,
          salutation: file.salutation || "-",
          client_id: this.getRandomInt(),
          company_name: file.company_name
            ? file.company_name
            : "default company",
        },
      };
      if (isPrivate) {
        delete data.business;
      } else {
        delete data.private;
      }
      let res = await this.clientService.save(data);
      if (res) {
        delete file.addPrivatperson;
        file.isAddClient = false;
        file.status_icon = this.getAssetPath("circle_tick.svg");
        this.$toast.success(
          isPrivate
            ? this.$i18n.t("clients.add.success")
            : this.$i18n.t("clients.add.success_business")
        );
      }
    },

    handleFinish() {
      if (this.files.length == 1 && this.files[0].property_id) {
        this.editProperty(this.files[0].property_id);
      } else {
        this.$router.push("/property/list");
      }
    },

    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
  },
  filters: {
    formatSize(size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size && size.toString() + " B";
    },
  },
};
</script>

<style scoped lang="scss">
.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Segoe UI Regular", "Segoe UI", sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  margin-bottom: 15px;
}

input[type="radio"] {
  border: 0px;
  width: 2em;
  height: 25px;
}

.textradio {
  font-family: "Segoe UI", sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}

.box {
  //height: 350px;
  //border: 2px dashed #c4c4c4;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-top: 11px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C4C4C4FF' stroke-width='2' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.title {
  font-family: "Segoe UI Semibold", "Segoe UI Regular", "Segoe UI", sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 18px;
}

.img {
  width: 107px;
  height: 107px;
}

.filelist {
  table-layout: fixed;
  width: 97%;

  td {
    text-align: left;
  }

  td.filename {
    font-family: "Segoe UI", sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
    white-space: nowrap;
    width: 170px;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    padding: 10px 10px 10px 0px;
  }

  td.action {
    width: 20px;
    padding-left: 10px;
  }

  .item {
    height: 45px;
    padding: 2px 2px 2px 2px;
    margin-bottom: 10px;
    border: 1px solid #dfdfdf !important;
    background-color: #eeffef;
    box-sizing: border-box;
  }
}

.upload-icon {
  width: 16px;
  height: 22px;
}

.link {
  font-family: "Segoe UI", sans-serif;
  color: $primary;
  cursor: pointer;
  text-align: right;
  text-decoration: underline;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.uploading-icon {
  width: 17px;
  height: 19px;
  animation: lds-dual-ring 1.2s linear infinite;
}
.help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}
</style>
