<!--suppress HtmlUnknownTag -->
<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('clients.edit.title')"
                :breadcrumbs="breadcrumbs"
                :sticky="true"
                :pagination="navRecords.length > 0 && getCurrentNavIndex > 0"
                :totalRecordCount="navRecords.length"
                :currentRecordIndex="getCurrentNavIndex"
                :showBottomLine="false"
                @page="changeNavPage"
    />

    <!-- Client basic info -->
    <div class="mt-4">
      <div class="flex space-x-4 mt-2">
        <div class="sub-title-label">
          {{ $t('clients.attrs.id') }}:
        </div>

        <div v-if="clientData.clientType==='private'" class="sub-title-value">
          {{ clientData.private.client_id }}
        </div>

        <div v-if="clientData.clientType==='business'" class="sub-title-value">
          {{ clientData.business.client_id }}
        </div>
      </div>

      <div class="flex space-x-4 mt-2">
        <div class="sub-title-label">
          {{ $t('clients.attrs.name') }}:
        </div>
        <div class="sub-title-value">
          {{ clientData.clientName }}
        </div>
      </div>

      <div class="flex space-x-4 mt-2">
        <div class="sub-title-label">
          {{ $t('properties.parcels') }}:
        </div>
        <div class="sub-title-value">
          {{ clientData.parcels_count }}
        </div>
      </div>
    </div>

    <hr class="hr-line-user mr-7 mt-4">

    <ModalDialog v-show="showDialog" headerText="Bestandsnachweis anfordern" @close="handleModalClose">
      <template v-slot:body>
        <div class="flex flex-col justify-start " v-show="!dStep2">

          <div class=" pr-8 mt-2 ">
            <div class="box">
              <div class=" flex justify-center items-center">
                <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
                <label class="text ml-2">Sofern der Mandant Eigentümer, Erbbau- oder Nutzungsberechtigter von mehreren
                  Grundstücken ist und Sie Informationen zum Gesamtbestand haben möchten, können Sie dies hier pro
                  Bundesland von den Landesämtern anfordern. Im Gegensatz zu Grundstücksnachweisen und
                  Flurstücksnachweisen werden ihnen hier alle Grundstücke zusammenfassend dargestellt.</label>
              </div>
            </div>
            <div class="box" style="background-color:  #fff4ce">
              <div class=" flex justify-start items-center">
                <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
                <label class="text ml-2">Achtung: Bitte beachten Sie, dass für die Anfragen pro Bundesland
                  unterschiedliche Amtsgebühren anfallen. Bitte geben Sie an, wer Empfänger der Rechnung für diese
                  Gebühren ist. Die Gebührenrechnungen werden direkt von den Ämtern an den Rechnungsempfänger gestellt.
                  Durch Nutzung dieses Services entstehen Ihnen bei uns keine Zusatzkosten.</label>
              </div>
            </div>
          </div>
          <div class="flex flex-col w-full">
            <label class="text  mb-4">Rechnungsempfänger</label>

            <div class="flex space-x-3  ">
              <div class="flex justify-start items-center cursor-pointer" @click="createOptionSelected('gmbh')">
                <RadioButton type="radio" v-model="radio" name="radio-button" value="gmbh" checked/>
                <span class="ml-2 textradio">  Kanzlei: Musterkanzlei GmbH</span>
              </div>
              <div class="flex justify-start items-center cursor-pointer " @click="createOptionSelected('timo')">
                <RadioButton type="radio" name="radio-button" v-model="radio" value="timo"/>
                <span class="ml-2 textradio">  Mandant: Timo Tauscher</span>
              </div>

            </div>
            <p class="text text-left mt-4 mb-1">Bundesländer:</p>
            <div class="flex ">

              <!--                 <p class="text  ml-14">Property Tax</p>-->
              <div class="chip-container w-full mr-6">

                <div class="chip" v-for="(chip, i) of chips" :key="chip.label">
                  <div class="flex flex-row">
                    <div class=" mr-1 h-1 w-1 rounded-full flex items-center  justify-center">
                      <!--                      <p class="chip-logo">{{ getInitials(chip) }}</p>-->
                    </div>
                    <p class="chip-text">
                      {{ chip }}
                    </p>
                  </div>
                  <img src="@/assets/close-chip.svg" @click="deleteChip(i)" alt=""/>
                </div>
                <input v-model="currentInput" @keypress.enter="saveChip" @keydown.delete="backspaceDelete">
              </div>
            </div>
          </div>

          <div class="flex justify-end space-x-10 mt-4 mr-6 ">
            <div class="flex mt-10 mb-10 space-x-4 ">
              <!--              <div class="bg-green flex items-center space-x-4 pr-3 pl-3 cursor-pointer " @click="goToDStep2">-->
              <!--                <img class="inventory" src="@/assets/inventory_white.svg"/>-->
              <!--                <label class=" label-white    bg-green">Bestandsnachweis jetzt anfordern </label>-->
              <!--              </div>-->

              <Button text="Bestandsnachweis jetzt anfordern" :icon="getAssetPath('inventory_white.svg')"
                      @click="goToDStep2"/>
              <Button class="w-44  " :text="$t('buttons.cancel')" :secondary="true"
                      @click="showDialog=false"></Button>
            </div>

          </div>
        </div>
        <div v-show="dStep2">
          <div class="flex justify-center items-center m-20">
            <div class=" flex flex-col justify-center items-center ml-15 mr-15">
              <div class="rounded-full flex items-center justify-center bg-green w-24 h-24 flex    m-2">
                <img src="@/assets/check_done.svg" alt=""/>
              </div>
              <label class="label__text ">Für den Mandanten Timo Tauscher wurden Bestandsnachweise
                angefordert in Hamburg und Berlin.</label>
              <Button @click="submit" class="mt-10 w-2/4 "
                      text="OK"></Button>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog v-show="emailDialog" :headerText="$t('settings.user_management.personal_message')" @close="closeEmailDialog">
      <template v-slot:body>
        <div class="flex flex-col justify-start ">
          <div class="flex justify-around mt-2 space-x-10">
            <div class="w-full flex flex-col justify-start mt-2">
              <label class="text-left textbold ">{{ $t('settings.user_management.attrs.email') }} : <span
                  style="text-decoration: underline">{{ emailRecipient.email }} </span></label>
              <div class="flex flex-col justify-start ">
                <InputText bodyClass="w-full non-resize-textarea h-72" class="invite_email_body"
                           :label="$t('settings.user_management.personal_message')" v-model="emailBody"
                           :isMultiline="true" :rows="20"/>
              </div>
            </div>
          </div>
          <div class="flex justify-end  mt-4  ">
            <div class="flex mt-10 mb-6 space-x-4">
              <Button class=" w-44 " @click="closeEmailDialog" :secondary="true"
                      :text="$t('buttons.cancel')"></Button>
              <Button class=" w-44 " @click="sendEmail" :text="$t('buttons.send')"></Button>
            </div>
          </div>
        </div>

      </template>

    </ModalDialog>
    <ModalDialog v-show="passwordDialog" :headerText="$t('clients.client_portal.reset_password')"
                 @close="closePasswordDialog">
      <template v-slot:body>
        <div class="flex flex-col justify-start ">
          <label class="password-text mt-10">{{ $t('clients.client_portal.reset_pwd_lbls.lbl1') }}
            <span style="color:#229d56; text-decoration: underline; font-weight:bold">{{ resetPwdEmail }}</span>
            {{ $t('clients.client_portal.reset_pwd_lbls.lbl2') }}
            {{ $t('clients.client_portal.reset_pwd_lbls.lbl3') }}</label>
          <div class="flex justify-end space-x-10 mt-4  ">
            <div class="flex mt-10 mb-10 ">
              <Button class=" w-44 " @click="closePasswordDialog" :text="$t('buttons.ok')" secondary></Button>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog v-if="addUserDialog" :headerText="$t('settings.user_management.menu.add')" modalSize="x-large"
                 @close="addUserDialog=false">
      <template v-slot:body>
        <div class="flex flex-col justify-start   mt-8 space-y-4">
          <div class="flex justify-around space-x-6 mb-4">
            <InputSelect class="w-full" :label="$t('general.attrs.salutation')" v-model="clientUserData.salutation"
                         :selected-value="clientUserData.salutation" :isRequired="true"
                         :isError="clientUserErrors.salutation.invalid" :errorMessage="$t('general.errors.required')"
                         :options="$t('general.salutation_with_none')"/>
            <div class="w-full mr-6 invisible"/>
          </div>
          <div class="flex justify-around space-x-6">
            <InputText class="w-full" :label="$t('general.attrs.first_name')" v-model="clientUserData.first_name"
                       :isRequired="true"
                       :isError="clientUserErrors.first_name.invalid" :errorMessage="$t('general.errors.required')"/>
            <InputText class="w-full mr-6" :label="$t('general.attrs.last_name')" v-model="clientUserData.last_name"
                       :isRequired="true"
                       :isError="clientUserErrors.last_name.invalid" :errorMessage="$t('general.errors.required')"/>
          </div>
          <div class="flex justify-around space-x-6">
            <InputText class="w-full" :label="$t('general.attrs.email')" v-model="clientUserData.email"
                      :isEmail="true"
                      :isRequired="true"
                      :isError="clientUserErrors.email.invalid" :errorMessage="$t('register.step1.emailError')"/>
            <InputText class="w-full mr-6"
                       :label="$t('clients.attrs.mobile')"
                       v-model="clientUserData.mobile"
                       :isRequired="clientUserData.mfa_mode==='SMS'"
                       :maxLength="16"
                       :isError="clientUserErrors.mobile.invalid"
                       :errorMessage="$t('general.errors.required')"
            />
          </div>
          <InputCheck :text="$t('clients.attrs.client_portal_sms_mfa')" :isChecked="clientUserData.mfa_mode==='SMS'"
                      @check="enableMfa($event, 'SMS')"
          />
          <InputCheck :text="$t('clients.client_portal.view_properties')"
                      :isChecked="view_property_check"
                      @check="selectPermission($event, 'VIEW_PROPERTY')"/>
          <InputCheck :text="$t('clients.client_portal.add_property')"
                      @check="selectPermission($event, 'ADD_PROPERTY')"/>
          <InputCheck :text="$t('clients.client_portal.edit_property')"
                      @check="selectPermission($event, 'EDIT_PROPERTY')"/>
          <InputCheck :text="$t('clients.client_portal.declaration_release')"
                      @check="selectPermission($event, 'RELEASE_DECLARATIONS')"/>
          <!--<InputCheck :text="$t('clients.client_portal.manage_users')"
                      @check="selectPermission($event, 'MANAGE_USERS')"/> -->

          <InputCheck :text="$t('clients.client_portal.set_default_user')"
                      :isChecked="parseInt(clientUserData.is_default) > 0"
                      :isDisabled="clientUserData.standard_user !== '' && parseInt(clientUserData.is_default) > 0"
                      @check="setDefaultUser($event)"/>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end">
          <div class="flex space-x-4 mt-4 mb-4 ">
            <Button class="w-44" @click="addUserDialog=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button class="w-44" :text="$t('buttons.save')" @click="addClientUser"></Button>
          </div>
        </div>
      </template>

    </ModalDialog>
    <ModalDialog v-show="editUserDialog" :headerText="$t('settings.user_management.menu.edit')"
                 modalSize="x-large"
                 @close="editUserDialog=false">
      <template v-slot:body>
        <div class="flex flex-col justify-start   mt-8 space-y-4">
          <div class="flex justify-around space-x-6 mb-4">
            <InputText class="w-full" :label="$t('clients.client_portal.username')" v-model="clientUserData.username"
                       :disabled="true"/>
            <InputSelect class="w-full mr-6" :label="$t('general.attrs.salutation')" v-model="clientUserData.salutation"
                         :selected-value="clientUserData.salutation" :isRequired="true"
                         :isError="clientUserErrors.salutation.invalid" :errorMessage="$t('general.errors.required')"
                         :options="$t('general.salutation_with_none')"/>
          </div>
          <div class="flex justify-around space-x-6">
            <InputText class="w-full" :label="$t('general.attrs.first_name')" v-model="clientUserData.first_name"
                       :isError="clientUserErrors.first_name.invalid" :errorMessage="$t('general.errors.required')"
                       :isRequired="true"/>
            <InputText class="w-full mr-6" :label="$t('general.attrs.last_name')" v-model="clientUserData.last_name"
                       :isError="clientUserErrors.last_name.invalid" :errorMessage="$t('general.errors.required')"
                       :isRequired="true"/>
          </div>
          <div class="flex justify-around space-x-6">
            <InputText class="w-full" :label="$t('general.attrs.email')" v-model="clientUserData.email"
                      :isEmail="true"
                      :isError="clientUserErrors.email.invalid" :errorMessage="$t('register.step1.emailError')"
                      :isRequired="true"/>
            <InputText class="w-full mr-6"
                       :label="$t('clients.attrs.mobile')"
                       v-model="clientUserData.mobile"
                       :isRequired="clientUserData.mfa_mode==='SMS'"
                       :maxLength="16"
                       :isError="clientUserErrors.mobile.invalid"
                       :errorMessage="$t('general.errors.required')"/>
          </div>

          <InputCheck :text="$t('clients.attrs.client_portal_sms_mfa')" :isChecked="clientUserData.mfa_mode==='SMS'"
                      @check="enableMfa($event, 'SMS')"
          />

          <InputCheck ref="editUserViewPropertyCheck" :text="$t('clients.client_portal.view_properties')"
                      :isChecked="clientUserData.permissions && clientUserData.permissions.includes('VIEW_PROPERTY')"
                      @check="selectPermission($event, 'VIEW_PROPERTY')"/>
          <InputCheck :text="$t('clients.client_portal.add_property')"
                      :isChecked="clientUserData.permissions && clientUserData.permissions.includes('ADD_PROPERTY')"
                      @check="selectPermission($event, 'ADD_PROPERTY')"/>
          <InputCheck :text="$t('clients.client_portal.edit_property')"
                      :isChecked="clientUserData.permissions && clientUserData.permissions.includes('EDIT_PROPERTY')"
                      @check="selectPermission($event, 'EDIT_PROPERTY')"/>
          <InputCheck :text="$t('clients.client_portal.declaration_release')"
                      :isChecked="clientUserData.permissions && clientUserData.permissions.includes('RELEASE_DECLARATIONS')"
                      @check="selectPermission($event, 'RELEASE_DECLARATIONS')"/>

          <InputCheck :text="$t('clients.client_portal.set_default_user')"
                      :isChecked="parseInt(clientUserData.is_default) > 0"
                      :isDisabled="clientUserData.standard_user !== '' && parseInt(clientUserData.is_default) > 0"
                      @check="setDefaultUser($event)"/>

          <!--<InputCheck :text="$t('clients.client_portal.manage_users')"
                      :isChecked="clientUserData.permissions && clientUserData.permissions.includes('MANAGE_USERS')"
                      @check="selectPermission($event, 'MANAGE_USERS')"/> -->
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end">
          <div class="flex mt-4 mb-4 space-x-4 ">
            <Button class="w-44" @click="editUserDialog=false" :text="$t('buttons.cancel')"
                    :secondary="true"></Button>
            <Button class="w-44" :text="$t('buttons.save')" @click="saveClientUser"></Button>
          </div>
        </div>
      </template>

    </ModalDialog>
    <ModalDialog v-show="showClientTypeChangeDialog" :headerText="$t('clients.confirmations.client_type_change.title')"
                 @close="cancelClientTypeChange">
      <template v-slot:body>
        <div class="flex flex-col justify-start">
          <label class="password-text mt-10">
            {{ $t('clients.confirmations.client_type_change.text1') }}
          </label>
          <label class="password-text mt-10">
            {{ $t('clients.confirmations.client_type_change.text2') }}
          </label>

          <div class="flex justify-end space-x-10 mt-4 mr-6 ">
            <div class="flex mt-10 mb-10 space-x-4 ">
              <Button :text="$t('general.no')" @click="cancelClientTypeChange" :secondary="true"/>
              <Button class="w-44" :text="$t('general.yes')" @click="saveClientTypeChange"/>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog v-show="showDatevClientSearchDialog" :headerText="$t('clients.datev_import.connect_with_datev')"
                 @close="hideDatevClientSearch">
      <template v-slot:body>
        <div class="flex justify-between">
          <InputText class="w-full" ref="datev-search-el" :label="$t('general.grid.search')"
                     v-model="datevClientSearchTerm" :requestFocus="true"/>
          <button class=" flex items-center justify-center feedback_button w-8 h-8 mt-6 "
                  @click.prevent="datevClientSearch"
          >
            <img class="w-4 h-4" :src="getAssetPath('arrow-right-white.svg')" alt=""/>
          </button>
        </div>
        <div class="datev-client-search-result">
          <div v-if="datevClientSearching">
            <div class="datev-client-search-loading mt-4">
              <i class="pi pi-spin pi-spinner"/>
            </div>
          </div>
          <div class="mt-5" v-else>
            <div class="flex justify-start items-center mr-6 cursor-pointer mb-5" v-for="datevClient in datevClientList" :key="datevClient.id">
              <RadioButton v-model="selectedSourceDatevClient" name="radio-button" :value="datevClient.id"/>
              <span class="ml-4 textradio cursor-pointer" @click="selectDatevClientRadio(datevClient.id)"> {{ datevClient.name }}</span>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end">
          <div class="flex mt-4 mb-4 space-x-4 ">
            <Button class="w-44" @click="hideDatevClientSearch" :text="$t('buttons.cancel')"
                    :secondary="true"></Button>
            <Button class="w-44" :text="$t('buttons.save')" @click="connectWithDatev"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- add client person modal -->
    <ModalDialog
        v-show="addPersonDialog && !isReadOnly"
        :headerText="$t('clients.menu.add')"
        @close="addPersonDialog=false"
        modalSize="x-large"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4" style="min-height: 600px; max-width: calc(99% - (10px));">
          <div class="flex justify-around space-x-10">
            <InputSelect class="mt-2 w-full"
                        :label="$t('clients.client_persons.salutation')"
                        :options="$t('general.salutation_with_none')"
                        :isError="personModalSaveErrors.salutation.invalid"
                        :errorMessage="$t('general.errors.required')"
                        :isRequired="true"
                        v-model="addModalSaveData.salutation"
                        :selected-value="addModalSaveData.salutation"
                        placeholder=""/>
            <InputText class="mt-2 w-full" v-model="addModalSaveData.title"
                      :label="$t('clients.attrs.title')"
                      placeholder=""/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full " v-model="addModalSaveData.first_name"
                      :isError="personModalSaveErrors.first_name.invalid"
                      :errorMessage="$t('general.errors.required')"
                      :label="$t('clients.client_persons.first_name')"
                      :isRequired="addClientPersonFirstNameRequired"
                      placeholder=""/>
            <InputText class="mt-2 w-full " v-model="addModalSaveData.last_name"
                      :isError="personModalSaveErrors.last_name.invalid"
                      :errorMessage="$t('general.errors.required')"
                      :label="$t('clients.client_persons.last_name')"
                      :isRequired="addClientPersonLastNameRequired"
                      placeholder=""/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                       v-model="addModalSaveData.tax_number"
                       :label="$t('clients.client_persons.tax_number')"
                       :isOnlyNumber="true"
                       :maxLength="13"
                       :showHelpIconPopover="true"
                       :popoverTexts="popoverTexts_tax_number"
                       :popoverLink="popoverLink_tax_number"
                       placeholder=""/>
            <InputText class="mt-2 w-full"
                       v-model="addModalSaveData.identification_number"
                       :label="$t('clients.client_persons.identification_number')"
                       :isOnlyNumber="true"
                       :maxLength="11"
                       placeholder=""/>
            <InputDate class="mt-2 w-full dob"
                       v-model="addModalSaveData.date_of_birth"
                       :label="$t('clients.client_persons.date_of_birth')"
                       :dob="true"
                       placeholder=""/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect class="mt-2 w-full"
                     :label="$t('clients.client_persons.status')"
                     :options="$t('clients.client_status_options')"
                     v-model="addModalSaveData.status"
                     :selected-value="addModalSaveData.status"
                     placeholder=""/>
            <InputText class="mt-2 w-full"
                      :label="$t('clients.client_persons.street')"
                      v-model="addModalSaveData.street"
                      placeholder=""/>
            <InputText class="mt-2 w-full "
                      :label="$t('clients.client_persons.house_number')"
                      v-model="addModalSaveData.house_number"
                      placeholder=""/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full" :label="$t('clients.client_persons.postbox')"
                        v-model="addModalSaveData.postbox"
                        placeholder=""/>
            <InputText class="mt-2 w-full"
                        :label="$t('clients.client_persons.zip')"
                        v-model="addModalSaveData.zip"
                        placeholder=""
                        @blur="searchZipForPersonForAdd"
                        :isZip="true"
                        :zipCountry="clientPersonAddModalCountry"
                        :errorMessage="$t('general.invalidZip')"
            />
            <InputSelect class="mt-2 w-full"
                        :label="$t('clients.client_persons.city')"
                        :options="cityListForPerson"
                        v-model="addModalSaveData.city"
                        :selected-value="addModalSaveData.city"
                        :loading="zipSearchForPerson"
                        :filterable="true"
                        :auto-complete="true"
                        :placeholder="$t('general.please_select')"
                        :maxLength="25"
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect class="mt-2 w-full"
                        :options="countries"
                        :label="$t('clients.client_persons.country')"
                        v-model="addModalSaveData.country"
                        :selected-value="addModalSaveData.country"
                        :filterable="true"
                        placeholder=""
            />
            <InputText class="mt-2 w-full"
                        :label="$t('clients.client_persons.email')"
                        type="email"
                        v-model="addModalSaveData.email"
                        :isError="personModalSaveErrors.email.invalid"
                        :isEmail="true"
                        :errorMessage="$t('general.errors.invalid_email')"
                        placeholder=""
            />
            <InputText class="mt-2 w-full"
                        :label="$t('clients.client_persons.mobile')"
                        :maxLength="16"
                        v-model="addModalSaveData.mobile"
                        placeholder=""
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                        :label="$t('clients.client_persons.phone')"
                        :maxLength="16"
                        v-model="addModalSaveData.phone"
                        placeholder=""
            />
            <InputText class="mt-2 w-full"
                        :label="$t('clients.client_persons.fax')"
                        :maxLength="16"
                        v-model="addModalSaveData.fax"
                        placeholder=""
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-6  ">
          <div class="flex mt-4 mb-8 space-x-4 ">
            <Button @click="addPersonDialog = false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="addPersonModalData"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- edit client person modal -->
    <ModalDialog
        v-show="editPersonDialog"
        :headerText="$t('clients.menu.edit')"
        @close="editPersonDialog=false"
        modalSize="x-large"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4" style=" min-height: 600px; max-width: calc(99% - (10px));">
          <div class="flex justify-around space-x-10">
            <InputSelect class="mt-2 w-full"
                        :label="$t('clients.client_persons.salutation')"
                        :options="$t('general.salutation_with_none')"
                        :isError="personModalSaveErrors.salutation.invalid"
                        :errorMessage="$t('general.errors.required')"
                        :isRequired="true"
                        v-model="editPersonModalSaveData.salutation"
                        :selected-value="editPersonModalSaveData.salutation"
                        :disabled="isReadOnly"
                        placeholder=""/>
            <InputText class="mt-2 w-full"
                        v-model="editPersonModalSaveData.title"
                        :label="$t('clients.attrs.title')"
                        :disabled="isReadOnly"
                        placeholder=""/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full "
                        v-model="editPersonModalSaveData.first_name"
                        :isError="personModalSaveErrors.first_name.invalid"
                        :errorMessage="$t('general.errors.required')"
                        :label="$t('clients.client_persons.first_name')"
                        :isRequired="true"
                        :disabled="isReadOnly"
                        placeholder=""/>
            <InputText class="mt-2 w-full "
                        v-model="editPersonModalSaveData.last_name"
                        :isError="personModalSaveErrors.last_name.invalid"
                        :errorMessage="$t('general.errors.required')"
                        :label="$t('clients.client_persons.last_name')"
                        :isRequired="true"
                        :disabled="isReadOnly"
                        placeholder=""/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                        v-model="editPersonModalSaveData.tax_number"
                        :label="$t('clients.client_persons.tax_number')"
                        :isOnlyNumber="true"
                        :maxLength="13"
                        :showHelpIconPopover="true"
                        :popoverTexts="popoverTexts_tax_number"
                        :popoverLink="popoverLink_tax_number"
                        :disabled="isReadOnly"
                        placeholder=""/>
            <InputText class="mt-2 w-full"
                        v-model="editPersonModalSaveData.identification_number"
                        :label="$t('clients.client_persons.identification_number')"
                        :isOnlyNumber="true"
                        :maxLength="11"
                        :disabled="isReadOnly"
                        placeholder=""/>
            <InputDate class="mt-2 w-full dob"
                        v-model="editPersonModalSaveData.date_of_birth"
                        :label="$t('clients.client_persons.date_of_birth')"
                        :dob="true"
                        :disabled="isReadOnly"
                        placeholder=""/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect class="mt-2 w-full"
                     :label="$t('clients.client_persons.status')"
                     :options="$t('clients.client_status_options')"
                     v-model="editPersonModalSaveData.status"
                     :selected-value="editPersonModalSaveData.status"
                     :disabled="isReadOnly"
                     placeholder=""/>

            <InputText class="mt-2 w-full"
                        :label="$t('clients.client_persons.street')"
                        v-model="editPersonModalSaveData.street"
                        :disabled="isReadOnly"
                        placeholder=""/>
            <InputText class="mt-2 w-full "
                        :label="$t('clients.client_persons.house_number')"
                        v-model="editPersonModalSaveData.house_number"
                        :disabled="isReadOnly"
                        placeholder=""/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                        :label="$t('clients.client_persons.postbox')"
                        v-model="editPersonModalSaveData.postbox"
                        :disabled="isReadOnly"
                        placeholder=""/>
            <InputText class="mt-2 w-full"
                        :label="$t('clients.client_persons.zip')"
                        v-model="editPersonModalSaveData.zip"
                        placeholder=""
                        @blur="searchZipForPersonForEdit"
                        :isZip="true"
                        :zipCountry="clientPersonEditModalCountry"
                        :errorMessage="$t('general.invalidZip')"
                        :disabled="isReadOnly"/>
            <InputSelect class="mt-2 w-full"
                        :label="$t('clients.client_persons.city')"
                        :options="cityListForPerson"
                        v-model="editPersonModalSaveData.city"
                        :selected-value="editPersonModalSaveData.city"
                        :loading="zipSearchForPerson"
                        :filterable="true"
                        :auto-complete="true"
                        :placeholder="$t('general.please_select')"
                        :maxLength="25"
                        :disabled="isReadOnly"/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect class="mt-2 w-full"
                          :options="countries"
                          :label="$t('clients.client_persons.country')"
                          v-model="editPersonModalSaveData.country"
                          :selected-value="editPersonModalSaveData.country"
                          :filterable="true"
                          placeholder=""
                          :disabled="isReadOnly"/>
            <InputText class="mt-2 w-full"
                        :label="$t('clients.client_persons.email')"
                        type="email"
                        v-model="editPersonModalSaveData.email"
                        :isError="personModalSaveErrors.email.invalid"
                        :isEmail="true"
                        :errorMessage="$t('general.errors.invalid_email')"
                        placeholder=""
                        :disabled="isReadOnly"/>
            <InputText class="mt-2 w-full"
                        :label="$t('clients.client_persons.mobile')"
                        :maxLength="16"
                        v-model="editPersonModalSaveData.mobile"
                        placeholder=""
                        :disabled="isReadOnly"/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                        :label="$t('clients.client_persons.phone')"
                        :maxLength="16"
                        v-model="editPersonModalSaveData.phone"
                        placeholder=""
                        :disabled="isReadOnly"/>
            <InputText class="mt-2 w-full"
                        :label="$t('clients.client_persons.fax')"
                        :maxLength="16"
                        v-model="editPersonModalSaveData.fax"
                        placeholder=""
                        :disabled="isReadOnly"/>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-6 ">
          <div class="flex my-6 space-x-4 ">
            <Button @click="editPersonDialog=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="editPersonModalData" v-if="!isReadOnly"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <AppTabs :categories="isBusiness() ? tabsBusiness : tabsTC"
             :split-save-button="true"
             v-if="renderTabs"
             @buttonClick="tabsButtonClicked" :selected-tab="activeTab">
      <!-- tab1 basic -->
      <template #tab-pane-basic>
        <div class="box flex justify-start items-center" v-show="clientData.freezed">
          <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
          <label class="text ml-2">
            {{ $t('clients.freezed_version_info.line1', {"version_number": clientData.freezed_version, "client_name": getClientName}) }}
            <router-link class="link" :to="'/declaration/edit?uid=' + clientData.freezed_declaration">{{ $t('declarations.detail.breadcrumbs.link1') }} {{ clientData.freezed_declaration_number }}</router-link>
            {{ $t('clients.freezed_version_info.line2') }}
            <router-link class="link" :to="'/client/edit?uid=' + clientData.freezed_declaration_client_uid">{{ $t('general.here') }}</router-link>.
            {{ $t('clients.freezed_version_info.line3') }}
          </label>
        </div>

        <!-- private person -->
        <div v-if="clientData.clientType==='private'">
          <div class="flex justify-around space-x-8">
            <div class="w-full flex flex-col">
              <div v-if="clientData.external_name==='datev'" class="flex justify-around space-x-8">
                <div class="w-1/2 flex flex-col">
                  <label class="title mb-1 flex items-start text-left">{{ $t('clients.attrs.id') }}</label>
                  <div class="mand flex justify-start items-center "><label
                      class="mand__text center">{{ clientData.private.client_id }}</label></div>
                </div>
                <div class="w-1/2 flex flex-col">
                  <label class="title mb-1 flex items-start text-left">{{ $t('clients.attrs.consultant_number') }}</label>
                  <div class="mand flex justify-start items-center "><label
                      class="mand__text center">{{ clientData.consultant_number }}</label></div>
                </div>
              </div>
              <div v-else>
                <InputText class="w-full" :disabled="isReadOnly" :label="$t('clients.attrs.id')"
                           v-model="clientData.private.client_id"
                           :isRequired="true"
                           :errorMessage="$t('general.errors.required')"
                           :isError="clientErrors.client_id.invalid"
                           placeholder=""
                />
              </div>
            </div>
            <div class="w-full flex flex-col">
              <InputSelect class="w-full"
                           v-model="clientType"
                           :selectedValue="clientType"
                           :label="$t('clients.attrs.type')"
                           :options="$t('clients.client_types')"
                           :isRequired="false" placeholder=""
                           :disabled="isReadOnly"
                           @on-item-selected="clientTypeChanged"
              />
            </div>
          </div>
          <div class="flex justify-around space-x-8 mt-4">
            <InputSelect class="w-full" :label="$t('general.attrs.salutation')"
                         :options="$t('general.salutation_with_none')"
                         :disabled="isReadOnly"
                         v-model="clientData.private.salutation"
                         :selected-value="clientData.private.salutation"
                         :isRequired="true"
                         placeholder=""/>
            <InputText class="w-full" :disabled="isReadOnly" :label="$t('clients.attrs.title')"
                       v-model="clientData.private.title"
                       placeholder=""/>
          </div>
          <div class="flex justify-around space-x-8 mt-4 ">
            <InputText class="w-full" :label="$t('general.attrs.first_name')"
                       v-model="clientData.private.first_name"
                       :isRequired="true"
                       :isError="clientErrors.first_name.invalid"
                       :errorMessage="$t('general.errors.required')"
                       placeholder="" :disabled="isReadOnly"/>
            <InputText class="w-full" :label="$t('general.attrs.last_name')"
                       v-model="clientData.private.last_name"
                       :isRequired="true"
                       :isError="clientErrors.last_name.invalid"
                       :errorMessage="$t('general.errors.required')"
                       placeholder="" :disabled="isReadOnly"/>
          </div>
          <div class="flex justify-around space-x-8 mt-4">
            <InputText :disabled="isReadOnly" class="w-full" :label="$t('clients.attrs.tax_number')"
                       v-model="clientData.private.tax_number" @input="formatTaxNumber" placeholder=""
                       :showHelpIconPopover="true"
                       :popoverTexts="popoverTexts_tax_number"
                       :popoverLink="popoverLink_tax_number"
            />
            <InputText class="w-full"
                       :disabled="isReadOnly"
                       :label="$t('clients.attrs.client_number')"
                       :isOnlyNumber="true"
                       :maxLength="11"
                       v-model="clientData.private.client_number" placeholder=""/>
          </div>
          <div class="flex justify-around space-x-8 mt-4">
            <InputDate
              class="w-full"
              :disabled="isReadOnly"
              dateId="clientdatebasic"
              v-model="clientData.private.dob"
              :label="$t('clients.attrs.dob')"
              :dob="true"
              placeholder=""
            />
            <InputCheck
              class="w-full mt-6 ml-2"
              :text="$t('clients.attrs.has_legal_representative')"
              :isChecked="clientData.hasLegalRepresentativeChecked"
              @check="handleHasLegalRepresentativeCheck"
            />
          </div>

          <div v-if="clientData.hasLegalRepresentativeChecked" class="mt-8">
            <div class="mt-5 flex flex-col">
              <label class="header">{{ $t('clients.attrs.legally_represented_by') }}</label>
              <hr class="hr-line-user mt-3">
            </div>

            <div class="flex justify-around space-x-8 mt-4">
              <InputSelect class="w-full" :label="$t('general.attrs.salutation')"
                           :options="$t('general.salutation_with_none')"
                           v-model="clientData.legal.salutation"
                           :selected-value="clientData.legal.salutation"
                           placeholder=""/>
              <InputText class="w-full" :label="$t('clients.attrs.title')"
                         v-model="clientData.legal.title"
                         placeholder=""/>
            </div>

            <div class="flex justify-around space-x-8 mt-4">
              <InputDate
                class="w-full"
                dateId="clientlegaldatebasic"
                v-model="clientData.legal.dob"
                :label="$t('clients.attrs.dob')"
                :dob="true"
                placeholder=""
              />
              <div class="w-full"></div>
            </div>

            <div class="flex justify-around space-x-8 mt-4 ">
              <InputText class="w-full" :label="$t('clients.attrs.first_name')"
                         v-model="clientData.legal.first_name"
                         :isRequired="true"
                         :isError="clientErrors.legal_first_name.invalid"
                         :errorMessage="$t('general.errors.required')"
                         placeholder=""/>
              <InputText class="w-full" :label="$t('clients.attrs.last_name')"
                         v-model="clientData.legal.last_name"
                         placeholder=""/>
            </div>

            <div class="flex justify-around space-x-8 mt-4 ">
              <InputText
                  class="w-full"
                  v-model="clientData.legal.street"
                  :maxLength="25"
                  :label="$t('clients.attrs.street')"
                  placeholder=""
              />
              <InputText
                  class="w-full"
                  v-model="clientData.legal.house_number"
                  :maxLength="39"
                  :label="$t('clients.attrs.house_number')"
                  placeholder=""
              />
            </div>

            <div class="flex justify-around space-x-8 mt-4 ">
              <InputText
                class="w-full"
                v-model="clientData.legal.zip"
                @blur="searchZipForLegal"
                :isZip="true"
                :zipCountry="clientData.legal.country_uid"
                :label="$t('clients.attrs.postcode')"
                placeholder=""
              />
              <InputSelect
                class="w-full"
                :label="$t('clients.attrs.city')"
                :options="cityListForBasic"
                v-model="clientData.legal.city"
                :selected-value="clientData.legal.city"
                :loading="zipSearchLegal"
                :filterable="true"
                :auto-complete="true"
                :placeholder="$t('general.please_select')"
                :maxLength="25"
              />
            </div>

            <div class="flex justify-around space-x-8 mt-4 ">
              <InputSelect
                class="w-full"
                :options="countries"
                :label="$t('clients.attrs.country')"
                :filterable="true"
                v-model="clientData.legal.country_uid"
                :selected-value="clientData.legal.country_uid"
                placeholder=""
              />
              <InputText
                  class="w-full"
                  v-model="clientData.legal.postbox"
                  :maxLength="6"
                  :label="$t('clients.attrs.po_box')"
                  placeholder=""
              />
            </div>

            <div class="flex justify-around space-x-8 mt-4 ">
              <InputText
                class="w-full"
                :label="$t('clients.attrs.phone')"
                :maxLength="16"
                v-model="clientData.legal.phone"
                placeholder=""
              />
              <div class="w-full invisible"/>
            </div>
          </div>

          <div class="mt-8 flex flex-col">
            <label class="header">Organisation</label>
            <hr class=" hr-line-user   mt-3">
          </div>

          <div class="flex justify-around space-x-8 mt-4">
            <InputSelect class="w-full " :label="$t('clients.attrs.status')"
                         :options="$t('clients.client_status_options')"
                         v-model="clientData.status"
                         :selected-value="clientData.status"
                         :disabled="isReadOnly" placeholder=""/>
            <InputSelect class="w-full" :label="$t('clients.attrs.branch')" :options="branchList"
                         v-model="clientData.branch"
                         :selected-value="clientData.branch"
                         :disabled="isReadOnly" placeholder=""/>
          </div>
          <div class="flex justify-around space-x-8 mt-4">

            <InputSelect
              class="w-full"
              :label="$t('settings.powers_of_attorney.default_power_of_attorney')"
              :options="powersOfAttorneyList"
              v-model="clientData.power_of_attorney"
              :selected-value="clientData.power_of_attorney"
              :disabled="isReadOnly"
              placeholder=""
            />
            <InputSelect
              class="w-full"
              :label="$t('clients.attrs.assignee')"
              :options="userList"
              v-model="clientData.assignee"
              :selected-value="clientData.assignee"
              :disabled="isReadOnly"
              :filterable="true"
              placeholder=""
            />
          </div>

          <div class="w-full mt-4">
            <InputText :label="$t('clients.attrs.internal_note')" :disabled="isReadOnly"
                       bodyClass="non-resize-textarea w-full" class="mt-4" v-model="clientData.note"
                       :isMultiline="true"/>
          </div>
        </div>
        <!-- company-->
        <div v-if="clientData.clientType==='business'">
          <div class="flex justify-around space-x-8">
            <div class="w-full flex flex-col">
              <div v-if="clientData.external_name==='datev'" class="flex justify-around space-x-8">
                <div class="w-full flex flex-col">
                  <label class="title mb-1 flex items-start text-left">{{ $t('clients.attrs.id') }}</label>
                  <div class="mand flex justify-start items-center "><label
                      class="mand__text center">{{ clientData.business.client_id }}</label></div>
                </div>
              </div>
              <div v-else>
                <InputText class="w-full" :disabled="isReadOnly" :label="$t('clients.attrs.id')"
                           v-model="clientData.business.client_id"
                           :isRequired="true"
                           :errorMessage="$t('general.errors.required')"
                           :isError="clientErrors.client_id.invalid"
                           placeholder=""/>
              </div>
            </div>
            <div class="w-full flex flex-col">
              <InputSelect class="w-full"
                           v-model="clientType"
                           :selectedValue="clientType"
                           :label="$t('clients.attrs.type')"
                           :options="$t('clients.client_types')"
                           :isRequired="false" placeholder=""
                           @on-item-selected="clientTypeChanged"
              />
            </div>
          </div>
          <div class="flex justify-around space-x-8 mt-4">
            <InputText class="w-full" :label="$t('general.attrs.name_company')"
                       v-model="clientData.business.company_name" :isRequired="true"
                       :maxLength="50"
                       placeholder=""/>
            <InputSelect class="w-full" :label="$t('general.attrs.salutation')"
                         :options="$t('clients.business_salutation')"
                         v-model="clientData.business.salutation"
                         :selected-value="clientData.business.salutation"
                         :isRequired="true"
                         placeholder=""/>

          </div>
          <div class="flex justify-around space-x-8 mt-4 ">
            <InputText class="w-full" :label="$t('clients.attrs.vat_number_wot_country')"
                       v-model="clientData.business.vat_number_wot_code"
                       :isOnlyNumber="true"
                       placeholder=""/>
            <InputSelect class="w-full" :label="$t('clients.attrs.vat_country_code')"
                         v-model="clientData.business.vat_country_code"
                         :selected-value="clientData.business.vat_country_code"
                         :options="getVatCountryCodes()"
                         placeholder=""/>
          </div>
          <div class="flex justify-around space-x-8 mt-4 ">
            <InputText class="w-full" :label="$t('clients.attrs.tax_number')"
                       v-model="clientData.business.tax_number"
                       @input="formatTaxNumberBusiness"
                       :maxLength="13"
                       :isOnlyNumber="true"
                       placeholder=""/>
            <InputSelect class="w-full" :label="$t('clients.attrs.legal_form')"
                         :isCategory="true"
                         :options="$t('clients.legal_forms')"
                         v-model="clientData.business.legal_form"
                         :selected-value="clientData.business.legal_form"
                         :filterable="true"
                         placeholder=""/>
          </div>
          <div class="mt-5 flex flex-col">
            <label class="header">Organisation</label>
            <hr class=" hr-line-user   mt-3">
          </div>
          <div class="flex justify-around space-x-8 mt-4">
            <InputSelect class="w-full" :options="$t('clients.client_status_options')"
                         :label="$t('clients.attrs.status')"
                         v-model="clientData.status"
                         :selected-value="clientData.status"
                         :isRequired="true" placeholder=""/>
            <InputSelect class="w-full" :label="$t('clients.attrs.branch')" :options="branchList"
                         v-model="clientData.branch"
                         :selected-value="clientData.branch"
                         placeholder=""/>
          </div>
          <div class="flex justify-around space-x-8 mt-4">

            <InputSelect
              class="w-full"
              :label="$t('settings.powers_of_attorney.default_power_of_attorney')"
              :options="powersOfAttorneyList"
              v-model="clientData.power_of_attorney"
              :selected-value="clientData.power_of_attorney"
              placeholder=""
            />
            <InputSelect
              class="w-full"
              :label="$t('clients.attrs.assignee')"
              :options="userList"
              v-model="clientData.assignee"
              :selected-value="clientData.assignee"
              :filterable="true"
              placeholder=""
            />
          </div>


          <div class="w-full mt-4">
            <InputText :label="$t('clients.attrs.internal_note')" :disabled="isReadOnly"
                       bodyClass="non-resize-textarea w-full" class="mt-4" v-model="clientData.note"
                       :isMultiline="true"/>
          </div>
        </div>
      </template>

      <!-- tab 2 address-->
      <template #tab-pane-address>
        <div class="flex justify-start items-center pt-4 mb-6" style="background-color: #f2f2f2;">
          <img class="image ml-2 mb-3.5" src="@/assets/info_gray.svg" alt=""/>
          <div class="text ml-4 mt-2 w-full">
            <p class="text-left mb-5 text-sm">
              {{ $t('clients.info-box') }}
            </p>
          </div>
        </div>
        <div class="flex justify-around space-x-8">
          <InputText class=" w-full" :label="$t('clients.attrs.street')" v-model="clientData.contact.street"
                     :disabled="isReadOnly"
                     placeholder=""/>
          <InputText class="w-full " :label="$t('clients.attrs.house_number')" v-model="clientData.contact.house_number"
                     :disabled="isReadOnly"
                     placeholder=""/>
          <InputText class="w-full" :label="$t('clients.attrs.po_box')" v-model="clientData.contact.po_box"
                     :disabled="isReadOnly"
                     placeholder=""/>
        </div>
        <div class="flex justify-around space-x-8 mt-4 ">
          <InputText
            class=" w-full"
            :label="$t('clients.attrs.postcode')"
            v-model="clientData.contact.postcode"
            :disabled="isReadOnly"
            @blur="searchZip"
            :isZip="true"
            :zipCountry="clientContactCountry"
            :errorMessage="$t('general.invalidZip')"
            placeholder=""
          />
          <InputSelect
            class="w-full"
            :disabled="isReadOnly"
            :label="$t('clients.attrs.city')"
            :options="cityListForContact"
            v-model="clientData.contact.city"
            :selected-value="clientData.contact.city"
            :loading="zipSearch"
            :filterable="true"
            :auto-complete="true"
            :placeholder="$t('general.please_select')"
            :maxLength="25"
          />
          <InputSelect class="w-full" :options="countries" :label="$t('clients.attrs.country')"
                       :disabled="isReadOnly"
                       :filterable="true"
                       v-model="clientData.contact.country" :selected-value="clientData.contact.country"
                       placeholder=""/>
        </div>
        <div class="flex justify-around space-x-8 mt-4">
          <InputText class="w-full" :label="$t('general.attrs.email')" v-model="clientData.contact.email"
                     :disabled="isReadOnly"
                     :isError="contactEmailInvalid"
                     :errorMessage="$t('general.errors.invalid_email')"
          />
          <div class="w-full invisible"/>
          <div class="w-full invisible"/>
        </div>
        <div class="flex justify-around space-x-8 mt-4">
          <InputText class=" w-full"
                     :label="$t('clients.attrs.mobile')"
                     :maxLength="16"
                     v-model="clientData.contact.mobile"
                     :disabled="isReadOnly"
                     placeholder=""/>
          <InputText class="w-full "
                     :label="$t('clients.attrs.phone')"
                     :maxLength="16"
                     v-model="clientData.contact.phone"
                     :disabled="isReadOnly"
                     placeholder=""/>
          <InputText class="w-full"
                     :label="$t('clients.attrs.fax')"
                     :maxLength="16"
                     v-model="clientData.contact.fax"
                     :disabled="isReadOnly"
                     placeholder=""/>
        </div>
      </template>

      <!-- tab 3 document-->
      <template v-if="!isReadOnly" #tab-pane-document>
        <Document grid-id="client_documents"
                  :parent-id="clientData.prim_uid"
                  parent-type="CLIENT"
                  :viewer-breadcrumbs="viewerBreadcrumbs"
                  :datev-client="isDatevClient"
        />
      </template>

      <!-- tab 4 Properties-->
      <template v-if="!isReadOnly" #tab-pane-properties>
        <properties grid-id="client_properties" parent-type="CLIENT" :parent-id="parseInt(clientData.prim_uid)"
                    :auto-load="false"
                    :archived="archived"
                    class="mb-5"
        />
      </template>

      <!-- tab 5 Mandenten-->
      <template v-if="!isBusiness() && !isReadOnly" #tab-pane-mandantenportal>
        <div class="flex justify-around space-x-10 mt-3 ">
          <div class="mt-2 w-full">
            <div class="flex justify-start ">
              <div class=" flex mr-10">
                <div class="flex justify-start">
                  <InputCheck :text="$t('clients.attrs.client_portal_access')"
                              :isChecked="clientData.client_portal_access"
                              @check="handleAccessCheck"/>
                </div>
                <div class="flex justify-end">
                  <!--      help icon with HTML tooltip/popover-->
                  <VDropdown offset="2" :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                             :hideTriggers="['click']">
                    <!-- This will be the popover target (for the events and position) -->
                    <img class="portal_access_help self-center  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                    <!-- This will be the content of the popover -->
                    <template #popper>
                      <div class="tooltip-content">
                        <div class="space-y-4">
                          <p class=" popover-text" v-for="(text, index) in $t('clients.client_portal.client_portal_access_help')"
                             v-html="text" :key="index">
                          </p>
                          <h2 style="line-height:80%; margin-top: 5px;"><a class="popover-text"
                                                                           :href="$t('clients.client_portal.client_portal_access_help_link')"
                                                                           target="_blank">{{
                              $t('clients.client_portal.client_portal_access_help_link')
                            }}</a></h2>

                        </div>
                      </div>
                    </template>
                  </VDropdown>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="box flex flex-col w-full mr-6 mt-5" v-show="portalAccessCheck">
          <div class="flex justify-start items-center h-full">
            <img class="image ml-2 mr-2" src="@/assets/info_gray.svg"/>
            <div class="flex flex-col">
              <label class="text ml-2">
                {{ $t('clients.client_portal.enable_sms_mfa_info') }}
              </label>
            </div>
          </div>
        </div>

        <!-- User Grid -->
        <div class="flex justify-around space-x-10 mt-3">
          <div class="mt-2 w-full">
            <div v-show="portalAccessCheck" class="client-user-grid">
              <hr class=" hr-line-user mb-1 mt-8 "/>
              <AppGrid :grid-id="clientUserGridId"
                       :use-local-data="true"
                       :local-data="clientData.users"
                       default-sort-attr="name"
                       :default-sort-dir="1"
                       :show-filter="false"
                       :show-select-col="false"
                       :columns="columns"
                       :selectable="true"
                       selection-data-key="prim_uid"
                       :state-ful="false"
                       :menus="menus"
                       @menu-clicked="handleUserMenuClick"
                       @action-menu-click="handleGridAction"
                       @col-click="handleUserColClick"
              />
            </div>
          </div>
        </div>
      </template>

      <!-- tab6 peoplepool -->
      <template v-if="!isReadOnly" #tab-pane-peoplepool>
        <div>
          <div class="mt-2">
            <AppGrid :grid-id="personGridId"
                     data-endpoint="client_person/list"
                     :columns="clientPersonColumns"
                     default-sort-attr="created_at"
                     :default-sort-dir="1"
                     :selectable="true"
                     selection-data-key="prim_uid"
                     :standard-filters="standardFilters"
                     :server-params="serverParams"
                     :menus="personMenus"
                     :show-filter="false"
                     :show-view-menu="false"
                     :state-ful="false"
                     :show-create-record="false"
                     :auto-load="false"
                     :is-read-only="isReadOnly"
                     @col-click="handlePersonColClick"
                     @menu-clicked="handlePersonMenuClick"/>
          </div>
        </div>
      </template>

      <!-- tab7 Protocol -->
      <template v-if="!isReadOnly" #tab-pane-protocol>
        <VersionGrid :parent-id="parseInt(clientData.prim_uid)" parent-type="clients"/>
      </template>

      <template #tab-footer v-if="isReadOnly">
        <div class="flex justify-end space-x-6 mt-5">
          <Button @click="cancelReadonly" class=" button-label  text-center   label__text-black "
                  :secondary="true"
                  :text="$t('buttons.cancel')"></Button>
        </div>
      </template>
      <template #tab-footer v-else-if="showUpdateFromDatev || showConnectWithDatev">
        <div class="flex justify-between space-x-6 mt-5">
          <Button @click="updateFromDatev" v-if="showUpdateFromDatev" class="button-label text-center bg-green text-white auto-width "
                  primary
                  :text="$t('clients.datev_import.update_from_datev')"/>
          <Button @click="showDatevClientSearch" v-else-if="showConnectWithDatev" class="button-label text-center bg-green text-white auto-width "
                  primary
                  :text="$t('clients.datev_import.connect_with_datev')"/>
          <div class="flex justify-end space-x-6">
            <Button @click="tabsButtonClicked('cancel')" class=" button-label  text-center   label__text-black "
                    :secondary="true"
                    :text="$t('buttons.cancel')"></Button>
            <ButtonWithOption
              @click="tabsButtonClicked"
              class="button-label  text-center bg-green  text-white "
              primary
              :text="$t('buttons.save')"
              :options="saveButtonWithOptions"
            ></ButtonWithOption>
          </div>
        </div>
      </template>
    </AppTabs>
    <div class="w-full h-full mr-8" v-else style="min-height: 600px;" />
  </div>
</template>

<script>
import AppTabs from "@/components/AppTabs";
import InputDate from "../inputs/InputDate";
import InputSelect from "../inputs/InputSelect";
import {ClientService} from "@/services/client.service";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {BranchService} from "@/services/branch.service";
import {PowersOfAttorneyService} from "@/services/powersofattorney.service";
import {UserService} from "@/services/user.service";
import Document from "../Document";
import VersionGrid from "../VersionGrid";
import properties from "@/components/properties/Properties";
import AppGrid from "@/components/AppGrid";
import {email, required, requiredIf} from "vuelidate/lib/validators";
import RadioButton from 'primevue/radiobutton';

export default {
  name: "EditClient",
  components: {RadioButton, InputSelect, AppTabs, Document, VersionGrid, properties, AppGrid},
  props: {
    set: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      navRecords: [],
      popoverTexts_tax_number: [
        this.$t('clients.tooltips.step2.tax_number1'),
        this.$t('clients.tooltips.step2.tax_number2'),
      ],
      popoverLink_tax_number: "https://hilfe.grundsteuer-digital.de/faq/steuernummer-privatperson-unternehmen/",
      service: new ClientService(),
      view_property_check: true,
      renderTabs: false,
      portalAccessCheck: false,
      clientUid: 0,
      clientData: {
        hasLegalRepresentativeChecked: false
      },
      initialClientDataString: null,
      clientErrors: {
        "first_name": {
          invalid: false
        },
        "last_name": {
          invalid: false
        },
        "client_id": {
          invalid: false
        },
        "legal_first_name": {
          invalid: false
        },
        "contactPostcode": {
          invalid: false
        }
      },
      countries: [],
      activeTab: '',
      tabsTCFields: [
        {
          name: this.$t('clients.edit.tabs.basic'),
          slug: 'basic',
          fields: [
            {
              name: this.$t('clients.attrs.id'),
              slug: 'client_id'
            }, {
              name: this.$t('general.attrs.first_name'),
              slug: 'first_name'
            }, {
              name: this.$t('general.attrs.first_name'),
              slug: 'legal_first_name'
            }, {
              name: this.$t('general.attrs.last_name'),
              slug: 'last_name'
            }, {
              name: this.$t('general.attrs.salutation'),
              slug: 'salutation'
            }
          ]
        }, {
          name: this.$t('clients.edit.tabs.address'),
          slug: 'address',
          fields:[
            {
              name: this.$t('general.attrs.email'),
              slug: 'email'
            },
          ]
        }, {
          name: this.$t('clients.edit.tabs.document'),
          showTopLine: true,
          slug: 'document',
          fields: []
        }, {
          name: this.$t('clients.edit.tabs.properties'),
          showTopLine: true,
          slug: 'properties',
          fields: []
        }, {
          name: this.$t('clients.edit.tabs.client_portal'),
          slug: 'mandantenportal',
          fields: []
        }, {
          name: this.$t('clients.edit.tabs.peoplepool'),
          showTopLine: true,
          slug: 'peoplepool',
          fields: []
        }, {
          name: this.$t('clients.edit.tabs.protocol'),
          showTopLine: true,
          slug: 'protocol',
          fields: []
        }
      ],
      tabsTC: [
        {
          name: this.$t('clients.edit.tabs.basic'),
          slug: 'basic'
        }, {
          name: this.$t('clients.edit.tabs.address'),
          slug: 'address'
        }, {
          name: this.$t('clients.edit.tabs.document'),
          showTopLine: true,
          slug: 'document'
        }, {
          name: this.$t('clients.edit.tabs.properties'),
          showTopLine: true,
          slug: 'properties'
        }, {
          name: this.$t('clients.edit.tabs.client_portal'),
          slug: 'mandantenportal'
        }, {
          name: this.$t('clients.edit.tabs.peoplepool'),
          showTopLine: true,
          slug: 'peoplepool'
        }, {
          name: this.$t('clients.edit.tabs.protocol'),
          showTopLine: true,
          slug: 'protocol'
        }
      ],
      tabsBusiness: [
        {
          name: this.$t('clients.edit.tabs.basic'),
          slug: 'basic'
        }, {
          name: this.$t('clients.edit.tabs.address'),
          slug: 'address'
        }, {
          name: this.$t('clients.edit.tabs.document'),
          slug: 'document'
        }, {
          name: this.$t('clients.edit.tabs.properties'),
          slug: 'properties'
        }, {
          name: this.$t('clients.edit.tabs.peoplepool'),
          showTopLine: true,
          slug: 'peoplepool'
        }, {
          name: this.$t('clients.edit.tabs.protocol'),
          slug: 'protocol'
        }
      ],
      branchList: [],
      powersOfAttorneyList: [],
      userList: [],
      breadcrumbs: [
        {
          title: this.$t('clients.title'),
          link: '/client/list'
        }, {
          title: this.$t('clients.menu.edit'),
          link: ''
        }
      ],
      viewerBreadcrumbs: [],
      cityListForBasic: [],
      cityListForContact: [],
      zipSearch: false,
      zipSearchLegal: false,
      showClientTypeChangeDialog: false,
      showDatevClientSearchDialog: false,
      datevClientSearching: false,
      datevClientSearchTerm: '',
      selectedSourceDatevClient: '',
      connectDatevImportKey: '',
      clientType: '',
      newClientType: '',
      datevClientList: [],
      emailRecipient: {
        "email": "",
        "clientUserId": ""
      },
      emailBody: '',
      emailTemplate: '',

      // Client Portal
      clientUserGridId: "client_users",
      columns: [
        {
          field: 'username',
          header: this.$t('clients.client_portal.username'),
          isChecked: true,
          sortable: false,
          filterable: true,
          clickable: true,
          menus: [
            {
              id: 'cu.ga.options',
              icon: 'verticle-dots.svg',
              class: 'ellipsis-v',
              items: [
                {
                  id: 'cu.ga.options.reset_password',
                  icon: 'reset_password_icon',
                  label: this.$t('clients.client_portal.reset_password')
                }, {
                  id: 'cu.ga.options.request_property',
                  icon: 'request_property_icon',
                  label: this.$t('clients.client_portal.request_add_property')
                }, {
                  id: 'cu.ga.options.login_as',
                  icon: 'login_as_icon',
                  label: this.$t('clients.client_portal.login_as')
                }
              ]
            }
          ]
        }, {
          field: 'name',
          header: this.$t('settings.user_management.attrs.name'),
          isChecked: true,
          sortable: false,
          filterable: true,
          clickable: true
        }, {
          field: 'email',
          header: this.$t('settings.user_management.attrs.email'),
          isChecked: true,
          sortable: false,
          filterable: true
        }, {
          field: 'mobile',
          header: this.$t('clients.attrs.mobile'),
          isChecked: true,
          sortable: false
        }, {
          field: 'standard_user',
          header: this.$t('clients.attrs.standard_user'),
          isChecked: true,
          sortable: false,
          html: true,
        }
      ],
      menus: [
        {
          id: "cu.add_user",
          "icon": this.getAssetPath('add_user.svg'),
          "title": this.$t('settings.user_management.menu.add'),
        }, {
          id: "cu.edit_user",
          "icon": this.getAssetPath('edit_user.svg'),
          "icon_disabled": this.getAssetPath('edit_user_gray.svg'),
          "title": this.$t('settings.user_management.menu.edit'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true,
        }, {
          id: "cu.delete_user",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('settings.user_management.menu.delete'),
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        },
        {
          id: "cu.other_actions",
          "icon": this.getAssetPath('other_actions.svg'),
          "icon_disabled": this.getAssetPath('other_actions_gray.svg'),
          "title": this.$t('settings.user_management.menu.other_actions'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true,
          "sub_menu": {
            items: [
              {
                id: 'cu.ga.options.reset_password',
                icon: 'reset_password_icon',
                label: this.$t('clients.client_portal.reset_password')
              }, {
                id: 'cu.ga.options.request_property',
                icon: 'request_property_icon',
                label: this.$t('clients.client_portal.request_add_property')
              }, {
                id: 'cu.ga.options.login_as',
                icon: 'login_as_icon',
                label: this.$t('clients.client_portal.login_as')
              }
            ]
          }
        }
      ],
      addingDefaultUser: false,
      clientUserId: '',
      userPermissions: ["VIEW_PROPERTY"],
      clientUserData: {
        mfa_mode: '',
        salutation: '-'
      },
      clientUserErrors: {
        "name": {
          invalid: false
        },
        "email": {
          invalid: false
        },
        "first_name": {
          invalid: false
        },
        "last_name": {
          invalid: false
        },
        "salutation": {
          invalid: false
        },
        "mobile": {
          invalid: false
        },
      },
      contactEmailInvalid: false,
      resetPwdEmail: '',

      chips: ['Berlin', 'Hamburg'],
      currentInput: '',
      radio: 'gmbh',
      emailDialog: false,
      passwordDialog: false,
      addUserDialog: false,
      editUserDialog: false,
      dStep2: false,
      showDialog: false,
      selectedOs: 'windows',
      smsMFASelected: false,
      isReadOnly: false,
      archived: false,

      personGridId: 'clientpersons',

      clientPersonColumns: [
        {
          field: 'salutation',
          header: this.$t('clients.client_persons.salutation'),
          isChecked: false,
          elType: 'select',
          clickable: true,
          filterOptions: this.$t('general.salutation_with_none')
        },
        {
          field: 'title',
          header: this.$t('clients.client_persons.title'),
          isChecked: true,
          elType: 'text',
          clickable: true
        },
        {
          field: 'firstName',
          header: this.$t('clients.client_persons.first_name'),
          isChecked: true,
          elType: 'text',
          clickable: true
        },
        {
          field: 'lastName',
          header: this.$t('clients.client_persons.last_name'),
          isChecked: true,
          elType: 'text',
          clickable: true
        },
        {
          field: 'taxNumber',
          header: this.$t('clients.client_persons.tax_number'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'identificationNumber',
          header: this.$t('clients.client_persons.identification_number'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'dateOfBirth',
          header: this.$t('clients.client_persons.date_of_birth'),
          isChecked: true,
          elType: 'date_range',
          operator: 'between',
          clickable: true
        },
        {
          field: 'status',
          header: this.$t('clients.client_persons.status'),
          isChecked: true,
          elType: 'select',
          clickable: true,
          filterOptions: this.$t('clients.client_status_options')
        },
        {
          field: 'street',
          header: this.$t('clients.client_persons.street'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'houseNumber',
          header: this.$t('clients.client_persons.house_number'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'postbox',
          header: this.$t('clients.client_persons.postbox'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'zip',
          header: this.$t('clients.client_persons.zip'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'city',
          header: this.$t('clients.client_persons.city'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'countryUid',
          header: this.$t('clients.client_persons.country'),
          isChecked: false,
          elType: 'select',
          filterableSelect: true,
          filterOptions: '',
          clickable: true
        },
        {
          field: 'email',
          header: this.$t('clients.client_persons.email'),
          isChecked: true,
          elType: 'text',
          clickable: true
        },
        {
          field: 'mobile',
          header: this.$t('clients.client_persons.mobile'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'phone',
          header: this.$t('clients.client_persons.phone'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'fax',
          header: this.$t('clients.client_persons.fax'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }
      ],

      standardFilters: [],

      serverParams: {
        parentType: 'client',
        parentId: '0'
      },

      personMenus: [
        {
          id: 'cm.add_person',
          icon: this.getAssetPath('add_user.svg'),
          title: this.$t('clients.menu.add')
        },
        {
          id: 'cm.edit_person',
          icon: this.getAssetPath('edit_user.svg'),
          icon_disabled: this.getAssetPath('edit_user_gray.svg'),
          title: this.$t('clients.menu.edit'),
          disable_on_multi: true,
          enable_on_select: true,
          disabled: true
        },
        {
          id: 'cm.delete_person',
          icon: this.getAssetPath('delete_user.svg'),
          icon_disabled: this.getAssetPath('delete_user_gray.svg'),
          title: this.$t('clients.menu.delete'),
          disable_on_multi: false,
          enable_on_select: true,
          disabled: true
        }
      ],

      addPersonDialog: false,
      editPersonDialog: false,

      editPersonModalSaveData: this.getDefaultEditPersonModal(),

      deleteData: {},

      zipSearchForPerson: false,
      cityListForPerson: [],

      addModalSaveData: {
        salutation: '',
        title: '',
        first_name: '',
        last_name: '',
        tax_number: '',
        identification_number: '',
        date_of_birth: '',
        status: '',
        street: '',
        house_number: '',
        zip: '',
        postbox: '',
        city: '',
        country: '',
        email: '',
        mobile: '',
        phone: '',
        fax: ''
      },

      personModalSaveErrors: {
        salutation: {
          invalid: false
        },
        first_name: {
          invalid: false
        },
        last_name: {
          invalid: false
        },
        zip: {
          invalid: false
        },
        email: {
          invalid: false
        }
      },

      saveButtonWithOptions: [{
        icon: require('@/assets/button_edit.svg'),
        label: this.$t('buttons.options.save_continue'),
        handler: 'handleSaveContinue',
        default: true,
        blackText: true,
        show: true,
        fontSize: '14',
      }, {
        icon: require('@/assets/button_add.svg'),
        label: this.$t('buttons.options.save_new'),
        handler: 'handleSaveNew',
        default: false,
        blackText: true,
        show: true,
        fontSize: '14',
      }, {
        icon: require('@/assets/button_list.svg'),
        label: this.$t('buttons.options.save_list'),
        handler: 'handleSaveList',
        default: false,
        blackText: true,
        show: true,
        fontSize: '14',
      }],

      addClientPersonFirstNameRequired: true,
      addClientPersonLastNameRequired: true
    }
  },
  validations: {
    clientUserData: {
      email: {
        required, email
      },
      first_name: {
        required
      },
      last_name: {
        required
      },
      mobile: {
        required: requiredIf(function () {
          return this.clientUserData.mfa_mode === 'SMS';
        })
      }
    },
    clientData: {
      contact: {
        email: {
          email
        }
      },
      private: {
        client_id: {
          required: requiredIf(function () {
            return this.clientData.external_name !== 'datev' && this.clientData.clientType === 'private';
          })
        },
        first_name: {
          required: requiredIf(function () {
            return this.clientData.clientType === 'private';
          })
        },
        last_name: {
          required: requiredIf(function () {
            return this.clientData.clientType === 'private';
          })
        }
      },
      legal: {
        first_name: {
          required: requiredIf(function () {
            return this.clientData.hasLegalRepresentativeChecked && this.clientData.clientType === 'private';
          })
        }
      },
      business: {
        client_id: {
          required: requiredIf(function () {
            return this.clientData.external_name !== 'datev' && this.clientData.clientType === 'business';
          })
        }
      }
    },

    addModalSaveData: {
      salutation: {
        required
      },
      first_name: {
        required
      },
      last_name: {
        required
      },
      email: {
        email
      }
    },

    editPersonModalSaveData: {
      id: {
        required: true
      },
      salutation: {
        required
      },
      first_name: {
        required
      },
      last_name: {
        required
      },
      email: {
        email
      }
    }
  },
  watch: {
    '$route'(to) {
      if (this.clientData.prim_uid && this.clientData.prim_uid !== to.query.uid) {
        this.fetchClientData();
      }
    },
    clientData: {
      handler(val) {
        this.setClientDataChanged(JSON.stringify(val) !== this.initialClientDataString);
        setTimeout(function () {
          if (val.private.tax_number) {
            val.private.tax_number = val.private.tax_number.substring(0, 13).replace(/[A-Z]/gi, '')
          }
        }, 300)
        setTimeout(function () {
          if (val.business.tax_number) {
            val.business.tax_number = val.business.tax_number.substring(0, 13).replace(/[A-Z]/gi, '')
          }
        }, 300)
      },
      deep: true
    },

    initialClientDataString(newValue) {
      this.setClientDataChanged(JSON.stringify(this.clientData) !== newValue);
    },

    triggerRefreshRecord(newValue) {
      if(newValue) {
        this.fetchClientData();
      }
    }
  },
  computed: {
    ...mapGetters("record_observer", ["triggerRefreshRecord"]),
    getClientType() {
      let client_type = this.$t('clients.client_types').filter((o) => {
        if (o.code === this.clientData.clientType) {
          return o;
        }
      });

      return client_type[0]['name'] ? client_type[0]['name'] : '';
    },
    getClientName() {
      if(this.clientData.clientType) {
        return this.clientData.clientType === 'private' ? this.clientData.private.first_name + ' ' + this.clientData.private.last_name : this.clientData.business.company_name
      }
      return '';
    },
    showUpdateFromDatev() {
      return typeof this.getCurrentUser().settings !== 'undefined'
        && this.getCurrentUser().settings.tax_office_software === 'datev'
        && this.clientData.external_name === 'datev'
        && this.clientData.external_id;
    },
    isDatevClient() {
      return this.clientData && typeof this.clientData.external_name !== 'undefined' && this.clientData.external_name === 'datev';
    },
    showConnectWithDatev() {
      return typeof this.getCurrentUser().settings !== 'undefined'
        && this.getCurrentUser().settings.tax_office_software === 'datev'
        && this.clientData.external_name !== 'datev'
        && this.clientData.external_id === '';
    },
    getCurrentNavIndex() {
      if(this.navRecords.length > 0 && parseInt(this.clientUid) > 0) {
        let nevRecordIndex = this.navRecords.findIndex(o => parseInt(o) === parseInt(this.clientUid));
        if(nevRecordIndex >= 0) {
          return nevRecordIndex + 1;
        }
      }

      return 0;
    },
    clientContactCountry() {
      return (typeof this.clientData.contact.country !== 'undefined') ? parseInt(this.clientData.contact.country) : 50;
    },
    clientPersonAddModalCountry() {
      return (typeof this.addModalSaveData.country !== 'undefined') ? parseInt(this.addModalSaveData.country) : 50;
    },
    clientPersonEditModalCountry() {
      return (typeof this.editPersonModalSaveData.country !== 'undefined') ? parseInt(this.editPersonModalSaveData.country) : 50;
    }
  },
  created() {
    let branchService = new BranchService();
    branchService.listMyBranches().then((list) => {
      this.branchList = list.map((o) => {
        return {
          "name": o.name,
          "code": o.uid
        }
      });
    });

    let powersOfAttorneyService = new PowersOfAttorneyService();
    powersOfAttorneyService.listAll().then((list) => {
      this.powersOfAttorneyList = list.map((o) => {
        return {
          "name": o.formattedName,
          "code": o.id
        }
      });
      this.powersOfAttorneyList.unshift({
        "name": this.$t('general.please_select'),
        "code": "0"
      })
    });

    this.fetchClientData();

    // fetch user list
    let userService = new UserService();
    userService.listUserByClient(this.$route.query.uid).then((list) => {
      this.userList = list.reduce((filtered, item) => {
        if (item.roleKey === 'administrator' || item.roleKey === 'user') {
          filtered.push({
            "name": item.name,
            "code": item.id
          });
        }

        return filtered;
      }, []);

      this.userList.unshift({
        "name": this.$t('general.please_select'),
        "code": "0"
      })
    });
  },
  mounted() {
    this.service.get_country_list().then((list) => {
      this.countries = list;
      for (let index = 0; index < this.clientPersonColumns.length; index++) {
        if (this.clientPersonColumns[index].field === 'country_uid') {
          this.clientPersonColumns[index].filterOptions = this.countries;
        }
      }
    })

    if(this.$route.params.nav_records && this.$route.params.nav_records.length > 0) {
      this.navRecords = this.$route.params.nav_records;
    }
  },
  beforeDestroy() {
    this.stopObserver();
  },
  methods: {
    ...mapGetters('client', ["getClientData"]),
    ...mapMutations('client', ["init", "setClientDataChanged"]),
    ...mapMutations("grid", ["triggerReload"]),
    ...mapGetters("user", ["getCurrentUser"]),
    ...mapActions("record_observer", ["startObserver", "stopObserver"]),
    ...mapMutations("user", ["saveAccessLog"]),
    changeNavPage(currentPage) {
      if(currentPage && this.navRecords.length > 0 && this.navRecords[currentPage-1]) {
        let navClientUid = parseInt(this.navRecords[currentPage-1]);
        if(navClientUid > 0) {
          this.stopObserver();
          this.$router.push('/client/edit?uid=' + navClientUid);
        }
      }
    },
    createOptionSelected(e) {
      this.radio = e
    },
    fetchClientData() {
      this.renderTabs = false;
      this.init();

      if(this.connectDatevImportKey) {
        this.service.trigger('client/datev_clients_fetched', {
          "importKey": this.connectDatevImportKey
        });
      }
      let currentTs = new Date().getTime();
      this.connectDatevImportKey = this.getCurrentUser().account_uid + '-' + this.getCurrentUser().id + '-connect-datev-' + this.$route.query.uid + '-'  + currentTs;

      this.isReadOnly = false;
      let hasLegalRepresentativeChecked = this.clientData.hasLegalRepresentativeChecked;
      this.clientData = this.getClientData();
      this.clientData.hasLegalRepresentativeChecked = hasLegalRepresentativeChecked;
      this.service.get(this.$route.query.uid).then(clientData => {
        if (clientData.prim_uid) {
          this.saveAccessLog({
            "recordType": "client",
            "recordUid": clientData.prim_uid
          });
          this.startObserver({
            "recordType": "clients",
            "recordId": clientData.prim_uid,
            "recordTimestamp" : clientData.record_timestamp
          });
          this.clientUid = clientData.prim_uid;

          this.serverParams.parentId = this.clientUid;

          this.renderTabs = true;
          this.clientData = {...this.clientData, ...clientData};
          this.clientType = this.clientData.clientType;
          if(this.clientData.legal && this.clientData.legal.first_name && this.clientData.legal.first_name !== '') {
            this.clientData.hasLegalRepresentativeChecked = true;
          } else {
            this.clientData.hasLegalRepresentativeChecked = false;
          }

          if(clientData.freezed) {
            this.isReadOnly = true;
          }

          if(this.$isBusiness) {
            this.tabsBusiness = this.tabsBusiness.map((o) => {
              o.hide = o.slug !== 'basic' && o.slug !== 'address' && this.isReadOnly;
              return o;
            });
          } else {
            this.tabsTC = this.tabsTC.map((o) => {
              o.hide = o.slug !== 'basic' && o.slug !== 'address' && this.isReadOnly;
              return o;
            });
          }

          let clientName = this.$t('clients.menu.edit');
          if (this.clientData.clientType === 'private') {
            clientName = clientData.private.first_name + ' ' + clientData.private.last_name;
          } else if (clientData.clientType === 'business') {
            clientName = clientData.business.company_name;
          }
          if (this.clientData.client_portal_access === true) {
            if (this.clientData.users.length > 0) {
              let defaultClientUser = this.clientData.users.filter((o) => {
                return o.is_default;
              });

              if (defaultClientUser && defaultClientUser[0].mfa_mode === 'SMS') {
                this.smsMFASelected = true;
              }

              this.portalAccessCheck = true;
              this.clientUserId = defaultClientUser[0]['uid'] ? defaultClientUser[0]['uid'] : '';
              this.clientData.client_portal_access = true;

              this.clientData.users = this.clientData.users.map((client_user) => {
                let standard_user = '';
                if (parseInt(client_user.is_default) === 1) {
                  standard_user = '<span class="default-user-checkmark"></span>';
                }
                client_user.standard_user = standard_user;

                client_user.permissions = Object.values(client_user.permissions);

                return client_user;
              });
            }
          } else {
            this.clientData.client_portal_access = false;
          }

          this.viewerBreadcrumbs = [
            {
              title: this.$t('clients.title'),
              link: '/client/list'
            }, {
              title: clientName.trim(),
              link: '/client/edit/?uid=' + this.clientData.prim_uid
            }, {
              title: this.$t('clients.edit.tabs.document'),
              backButtonHandler: true,
              handler: () => {
                this.$router.push({
                  name: "client",
                  params: {
                    'comp': 'edit',
                    'selectedTab': 'document'
                  },
                  query: {
                    'uid': this.clientData.prim_uid
                  }
                });
              }
            }
          ];

          if (this.$route.params.selectedTab) {
            this.activeTab = this.$route.params.selectedTab;
          }

          if (this.clientData.contact.country === '' || this.clientData.contact.country === '0') {
            this.clientData.contact.country = this.defaultCountryId;
          }

          if (this.clientData.note === null) {
            this.clientData.note = '';
          }

          // Get declaration data string
          let clientDataLocalCopy = _.clone(this.clientData);
          this.initialClientDataString = JSON.stringify(clientDataLocalCopy);

          this.triggerReload(this.clientUserGridId);
        } else {
          this.$router.push('/client/list')
        }
      });
    },
    handleAccessCheck(isChecked) {
      this.clientData.client_portal_access = isChecked;
      if (isChecked) {
        if (typeof (this.clientData.contact.email) === 'undefined' || this.clientData.contact.email.trim() === '') {
          this.$api.showToast(this.$t('clients.errors.required_contact_email'), "error");
          this.portalAccessCheck = false;
          this.$nextTick(() => {
            this.clientData.client_portal_access = false;
          });
          return;
        }

        if (this.smsMFASelected && (typeof (this.clientData.contact.mobile) === 'undefined' || this.clientData.contact.mobile.trim() === '')) {
          this.$api.showToast(this.$t('clients.errors.required_contact_mobile'), "error");
          this.portalAccessCheck = false;
          this.$nextTick(() => {
            this.clientData.client_portal_access = false;
          });
          return;
        }

        this.addingDefaultUser = true;
        this.emailTemplate = 'client_user_welcome';
        this.emailRecipient.email = this.clientData.contact.email;
        this.showEmailDialog();
      } else {
        this.portalAccessCheck = false;
      }
    },
    addDefaultClientUser() {
      this.service.saveClientUser({
        'name': (this.clientData.clientType === 'business' ? this.clientData.business.company_name : (this.clientData.private.first_name + ' ' + this.clientData.private.last_name)),
        'email': this.clientData.contact.email ? this.clientData.contact.email : '',
        'mobile': this.clientData.contact.mobile ? this.clientData.contact.mobile : '',
        'salutation': this.clientData.clientType === 'private' && this.clientData.private.salutation ? this.clientData.private.salutation : '-',
        'client_uid': this.clientData.prim_uid,
        'single_user': 1,
        'mfa_mode': this.smsMFASelected ? 'SMS' : '',
        'permissions': ['ALL'],
        'email_body': this.emailBody
      }, true).then((client_user) => {
        if (client_user.uid) {
          this.$api.showToast(this.$t('clients.client_portal.user_added_info'))
          this.clientUserId = client_user.uid;
          let found = this.clientData.users.filter((o) => {
            return o.prim_uid === client_user.prim_uid;
          });

          if (found.length === 0) {
            let standard_user = '';
            if (parseInt(client_user.is_default) === 1) {
              standard_user = '<span class="default-user-checkmark"></span>';
            }
            client_user.standard_user = standard_user;

            this.clientData.users.push(client_user);
            this.triggerReload(this.clientUserGridId);
          }
          this.portalAccessCheck = true;
        } else if(client_user.error_key === 'clientAlreadyHavePortalAccess') {
          this.portalAccessCheck = true;
          this.triggerReload(this.clientUserGridId);
        } else {
          this.portalAccessCheck = false;
          this.$nextTick(() => {
            this.clientData.client_portal_access = false;
          });
        }
        this.addingDefaultUser = false;
      });
    },
    handleMfaCheck(isChecked) {
      this.smsMFASelected = isChecked;
      if (isChecked) {
        if (typeof (this.clientData.contact.mobile) === 'undefined' || this.clientData.contact.mobile.trim() === '') {
          this.$api.showToast(this.$t('clients.errors.required_contact_mobile'), "error");
          this.unselectSmsMFA();
        } else {
          this.toggleMfa('SMS');
        }
      } else {
        this.toggleMfa('');
      }
    },
    toggleMfa(mfa_mode) {
      let defaultClientUser = this.clientData.users.filter((o) => {
        return o.is_default;
      });
      if (defaultClientUser) {
        let postData = {
          'clientUid': this.clientData.prim_uid,
          'clientUserId': defaultClientUser[0].prim_uid,
          'mfaMode': mfa_mode,
          'mobile': this.clientData.contact.mobile
        };
        this.service.toggleMFA(postData, true).then((res) => {
          if (!res) {
            this.unselectSmsMFA();
          }
        })
      } else {
        this.unselectSmsMFA();
      }
    },
    unselectSmsMFA() {
      this.$nextTick(() => {
        this.smsMFASelected = !this.smsMFASelected;
      });
    },
    handleUserColClick(e) {
      if ((e.field === 'username' || e.field === 'name') && parseInt(e.row.prim_uid) > 0) {
        this.triggerUserEdit(e.row);
      }
    },
    handleUserMenuClick(args) {
      switch (args.menuId) {
        case 'cu.add_user':
          this.userPermissions = ["VIEW_PROPERTY"];
          this.clientUserData = {
            'mfa_mode': '',
            'salutation': '-',
            'email_body': '',
            'is_default': '0'
          };

          if(this.clientData.clientType === 'private') {
            this.clientUserData.salutation = this.clientData.private.salutation;
          }

          this.addUserDialog = true;
          this.$nextTick(() => {
            this.resetClientUserValidation();
          });
          break;
        case 'cu.edit_user':
          this.triggerUserEdit(args.data[0]);
          break;
        case 'cu.delete_user':
          this.$modal.show({
            headerText: this.$t('general.grid.delete_record'),
            text: this.$t('general.grid.delete_confirm'),
            showClose: true,
            canEscape: true,
            onConfirm: () => {
              this.service.delete_user(this.clientData.prim_uid, args.data).then((res) => {
                if (res) {
                  this.service.listClientUsers(this.clientData.prim_uid, true).then((client_users) => {
                    this.clientData.users = this.service.parse_object_data(client_users);
                    this.clientData.users = this.clientData.users.map((client_user) => {
                      let standard_user = '';
                      if (parseInt(client_user.is_default) === 1) {
                        standard_user = '<span class="default-user-checkmark"></span>';
                      }
                      client_user.standard_user = standard_user;

                      return client_user;
                    });

                    this.triggerReload(this.clientUserGridId)
                  })
                }
              });
            }
          });
          break;
        case 'cu.ga.options.reset_password':
          this.resetPassword(args.data[0].prim_uid)
          break;
        case 'cu.ga.options.request_property':
          this.emailRecipient.email = args.data[0].email;
          this.emailRecipient.clientUserId = args.data[0].prim_uid;
          this.emailTemplate = 'client_user_invitation';
          this.showEmailDialog();
          break;
        case 'cu.ga.options.login_as':
          this.loginAsClientUser(args.data[0].prim_uid)
          break;
      }
    },
    handleGridAction(args) {
      switch (args.menu.id) {
        case 'cu.ga.options.reset_password':
          this.resetPassword(args.row.prim_uid)
          break;
        case 'cu.ga.options.request_property':
          this.emailRecipient.email = args.row.email;
          this.emailRecipient.clientUserId = args.row.prim_uid;
          this.emailTemplate = 'client_user_invitation';
          this.showEmailDialog();
          break;
        case 'cu.ga.options.login_as':
          this.loginAsClientUser(args.row.prim_uid)
          break;
      }
    },
    resetClientUserValidation() {
      // Reset validation status
      for (let attr in this.clientUserErrors) {
        this.clientUserErrors[attr].invalid = false;
      }
    },
    triggerUserEdit(record) {
      this.clientUserData = record;
      this.clientUserData.permissions = this.userPermissions = this.service.parse_object_data(this.clientUserData.permissions);
      if (this.clientUserData.salutation === '') {
        this.clientUserData.salutation = '-';
      }
      this.editUserDialog = true;
    },
    validateClientUser() {
      this.resetClientUserValidation();
      this.$v.$touch();

      let invalidUser = false;
      if (this.$v.$invalid) {
        for (let attr in this.clientUserErrors) {
          if (this.$v.clientUserData[attr]) {
            this.clientUserErrors[attr].invalid = this.$v.clientUserData[attr].$error;

            if (this.$v.clientUserData[attr].$error) {
              invalidUser = true;
            }
          }
        }

        if (invalidUser) {
          return false;
        }
      }

      this.clientUserData.permissions = this.userPermissions;
      this.clientUserData.client_uid = this.clientData.prim_uid;
      if (this.clientUserData.prim_uid) {
        this.clientUserData.name = '';
      }
      return true;
    },
    addClientUser() {
      if(!this.validateClientUser()) {
        return false;
      }
      this.emailTemplate = 'client_user_welcome';
      this.emailRecipient.email = this.clientUserData.email;
      this.addUserDialog = false;
      this.showEmailDialog();
    },
    saveClientUser() {
      if(!this.validateClientUser()) {
        return false;
      }

      this.service.saveClientUser(this.clientUserData, true).then((client_user) => {
        if (client_user.prim_uid) {
          if (this.clientUserData.prim_uid) {
            this.clientData.users = this.clientData.users.filter((o) => {
              return o.prim_uid !== this.clientUserData.prim_uid;
            });
          }

          client_user.standard_user = '';
          if (parseInt(client_user.is_default) === 1) {
            let old_default_user = '';
            this.clientData.users = this.clientData.users.map((clientuser) => {
              if (parseInt(clientuser.is_default) > 0) {
                old_default_user = clientuser.first_name + ' ' + clientuser.last_name;
              }

              clientuser.is_default = '0';
              clientuser.standard_user = '';

              return clientuser;
            });

            if (old_default_user.trim() !== '') {
              let new_default_user = this.clientUserData.first_name + ' ' + this.clientUserData.last_name;
              let message = this.$t('general.default_user_changed').replace('%old_default_user%', old_default_user.trim()).replace('%new_default_user%', new_default_user.trim());
              this.$api.showToast(message);
            }

            client_user.standard_user = '<span class="default-user-checkmark"></span>';
          }

          if (parseInt(client_user.is_default) === 1) {
            this.clientData.users = this.clientData.users.map((clientuser) => {
              clientuser.is_default = '0';
              clientuser.standard_user = '';

              return clientuser;
            });

            this.triggerReload(this.clientUserGridId)
          }

          let existingClientUser = this.clientData.users.find(user => user.prim_uid === client_user.prim_uid);
          if (typeof existingClientUser === 'undefined') {
            this.clientData.users.push(client_user);
          }

          this.addUserDialog = false;
          this.editUserDialog = false;
          this.emailDialog = false;
          this.triggerReload(this.clientUserGridId)
        }
      })
    },
    enableMfa(checked, mfa_mode) {
      this.clientUserData.mfa_mode = checked ? mfa_mode : '';
    },
    selectPermission(checked, permission) {
      if (permission === 'VIEW_PROPERTY') {
        this.view_property_check = checked;
      }

      if (checked) {
        if (permission === 'ADD_PROPERTY' && checked) {
          this.view_property_check = true
        }

        if (this.editUserDialog === true && permission === 'ADD_PROPERTY') {
          this.$refs.editUserViewPropertyCheck.$refs.checkbox.checked = checked
        }

        this.userPermissions.push(permission);
      } else {
        let index = this.userPermissions.indexOf(permission);
        if (index > -1) {
          this.userPermissions.splice(index, 1);
        }
      }
    },

    setDefaultUser(checked) {
      if (checked) {
        this.clientUserData.is_default = '1';
      } else {
        this.clientUserData.is_default = '0';
      }
    },

    tabsButtonClicked(btn_key) {
      let saveBtnOptions = ["saveContinue", "saveNew", "saveList", "save"];
      if (btn_key === 'cancel') {
        this.$router.push('/client/list');
      } else if(saveBtnOptions.includes(btn_key)) {
        this.saveClient(btn_key);
      }
    },
    selectDatevClientRadio(datevClientId) {
      this.selectedSourceDatevClient = datevClientId;
    },
    datevClientSearch() {
      if(this.datevClientSearchTerm) {
        this.datevClientSearching = true;
        this.service.find_datev_client({
          query: this.datevClientSearchTerm,
          client_uid: this.clientData.prim_uid,
          client_type: this.clientData.clientType,
          importKey: this.connectDatevImportKey
        }).then((response) => {
          this.datevClientSearching = false;
          if (response.data.success && response.data.clients) {
            this.datevClientList = this.$api.parse_object_data(response.data.clients);
          } else {
            this.datevClientList = [];
          }
        });
      }
    },
    connectWithDatev() {
      if(this.selectedSourceDatevClient) {
        this.service.connect_with_datev(this.selectedSourceDatevClient, this.clientData.prim_uid).then((response) => {
          this.hideDatevClientSearch();
          if(response.data.success) {
            this.$modal.show({
              headerText: this.$t('clients.datev_import.connect_with_datev'),
              text: this.$t('clients.datev_import.record_updated'),
              showClose: false,
              canEscape: false,
              showCancel: false,
              buttons: {
                ok: this.$t('buttons.ok'),
                delete: false
              },
              onConfirm: () => {
                this.stopObserver();
                this.fetchClientData();
              }
            });
          } else {
            this.$api.showToast(response.data.message, "error")
          }
        });
      }
    },
    showDatevClientSearch() {
      this.selectedSourceDatevClient = '';
      this.datevClientSearchTerm = '';
      this.datevClientList = [];
      this.showDatevClientSearchDialog = true;
      this.$nextTick(() => this.$refs["datev-search-el"].setFocus())
    },
    hideDatevClientSearch() {
      this.showDatevClientSearchDialog = false;
    },
    updateFromDatev() {
      this.service.update_from_datev(this.clientData.external_id, this.clientData.external_etag, this.clientData.branch).then((response) => {
        if (response.data.success && response.data.info_key) {
          if(response.data.info_key === 'dataNotChanged') {
            this.$modal.show({
              headerText: this.$t('clients.datev_import.update_from_datev'),
              text: this.$t('clients.datev_import.no_change_found'),
              showClose: false,
              canEscape: false,
              showCancel: false,
              buttons: {
                ok: this.$t('buttons.ok'),
                delete: false
              },
            });
          } else if(response.data.info_key === 'dataUpdated') {
            this.$modal.show({
              headerText: this.$t('clients.datev_import.update_from_datev'),
              text: this.$t('clients.datev_import.record_updated'),
              showClose: false,
              canEscape: false,
              showCancel: false,
              buttons: {
                ok: this.$t('buttons.ok'),
                delete: false
              },
              onConfirm: () => {
                this.stopObserver();
                this.fetchClientData();
              }
            });
          }
        } else {
          this.$api.showToast(response.data.message, "error")
        }
      })
    },
    async saveClient(btn_key) {
      if (this.isReadOnly) {
        return;
      }

      this.contactEmailInvalid = false;
      this.clientErrors.first_name.invalid = false;
      this.clientErrors.last_name.invalid = false;
      this.clientErrors.legal_first_name.invalid = false;

      if (typeof this.clientData.contact.email !== 'undefined' && this.clientData.contact.email !== '') {
        this.clientData.contact.email = this.clientData.contact.email.trim();
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        let stopSubmit = false;
        if (this.clientData.contact.email !== '' && this.$v.clientData.contact.email.$error) {
          this.contactEmailInvalid = true;
          stopSubmit = true;
          this.showErrorNotification('email')
        }
        if (this.$v.clientData.private.first_name.$error) {
          this.clientErrors.first_name.invalid = true;
          stopSubmit = true;
          this.showErrorNotification('first_name')
        }
        if (this.$v.clientData.private.client_id.$error || this.$v.clientData.business.client_id.$error) {
          this.clientErrors.client_id.invalid = true;
          stopSubmit = true;
          this.showErrorNotification('client_id')
        }
        if (this.$v.clientData.private.last_name.$error) {
          this.clientErrors.last_name.invalid = true;
          stopSubmit = true;
          this.showErrorNotification('last_name')
        }
        if (this.clientUserErrors.salutation.invalid) {
          this.showErrorNotification("salutation")
        }
        if (this.clientUserErrors.mobile.invalid) {
          this.showErrorNotification("mobile")
        }
        if (this.clientData.hasLegalRepresentativeChecked && this.$v.clientData.legal.first_name.$error) {
          this.clientErrors.legal_first_name.invalid = true;
          stopSubmit = true;
          this.showErrorNotification('legal_first_name')
        }
        if (stopSubmit) {
          return;
        }
      }

      // Trigger save
      this.stopObserver();
      const service = new ClientService();
      let post_data = this.clientData;
      post_data.hasLegalRepresentativeChecked = this.clientData.hasLegalRepresentativeChecked;
      let res = await service.save(post_data);
      if (res) {
        this.startObserver({
          "recordType": "clients",
          "recordId": this.clientData.prim_uid,
          "recordTimestamp" : res.recordTimestamp
        });

        if (this.clientData.users.length > 0 && !this.clientData.client_portal_access) {
          this.clientData.users = [];
        }
        this.$api.showToast(this.$t('general.data_saved'));

        if (!this.clientData.hasLegalRepresentativeChecked) {
          // reset client legal
          this.resetClientLegal();
        }

        // Get declaration data string
        let clientDataLocalCopy = _.clone(this.clientData);
        this.initialClientDataString = JSON.stringify(clientDataLocalCopy);

        if (btn_key === 'saveList') {
          await this.$router.push('/client/list')
        } else if (btn_key === 'saveNew') {
          await this.$router.push('/client/add')
        } else if (post_data['delete_other_users'] && this.clientData.users.length > 0) {
          this.clientData.users = this.clientData.users.filter((o) => {
            return o.is_default === '1';
          });
          this.triggerReload(this.clientUserGridId);
        }
      }
    },
    resetClientLegal() {
        var legal = {
            salutation: '',
            title: '',
            dob: '',
            first_name: '',
            last_name: '',
            street: '',
            house_number: '',
            zip: '',
            city: '',
            country_uid: '',
            postbox: '',
            phone: ''
        };
        this.clientData.legal = {...this.clientData.legal, ...legal};
    },
    showErrorNotification(field) {
      let outerField = this.tabsTCFields.find(x => x.fields.find((str) => str.slug === field))
      let field_index = this.tabsTCFields.findIndex(x => x.fields.find((str) => str.slug === field))
      let field_name = this.tabsTCFields[field_index].fields.find((str) => str.slug === field)
      let message = this.$t('general.check_fields').replace('%tab%', outerField.name).replace('%field%', field_name.name)
      this.$api.showToast(message, 'error');
    },
    searchZipForLegal() {
      this.zipSearchLegal = true;
      if ((typeof this.clientData.legal.city === 'undefined' || this.clientData.legal.city === '') && this.clientData.legal.zip !== '') {
        this.cityListForBasic = [];
        this.$api.lookup_city(this.clientData.legal.zip).then((cities) => {
          this.cityListForBasic = cities;
          if (cities.length === 1) {
            this.clientData.legal.city = cities[0].code;
          }
          this.zipSearchLegal = false;
        });
      }
    },
    searchZip() {
      this.zipSearch = true;
      if ((typeof this.clientData.contact.city === 'undefined' || this.clientData.contact.city === '') && typeof this.clientData.contact.postcode !== 'undefined' && this.clientData.contact.postcode !== '') {
        this.cityListForContact = [];
        this.$api.lookup_city(this.clientData.contact.postcode).then((cities) => {
          this.cityListForContact = cities;
          if (cities.length === 1) {
            this.clientData.contact.city = cities[0].code;
          }
          this.zipSearch = false;
        });
      }
    },
    showEmailDialog() {
      this.$api.getEmailTemplate(this.emailTemplate, true).then((content) => {
        this.emailBody = content
            .replace('%sender_name%', this.getCurrentUser().name)
            .replace('%sender_company_name%', this.getCurrentUser().company_name)
            .replace(/<br\s*[\/]?>/gi, "");
        this.emailDialog = true
      });
    },
    sendEmail() {
      if(this.emailTemplate === 'client_user_invitation') {
        this.service.sendUserInvite({
          clientUid: this.clientData.prim_uid,
          clientUserId: this.emailRecipient.clientUserId,
          emailBody: this.emailBody
        }).then((res) => {
          if (res) {
            this.emailTemplate = '';
            this.emailDialog = false;
          }
        })
      } else if(this.emailTemplate === 'client_user_welcome') {
        if(this.addingDefaultUser) {
          this.emailDialog = false;
          this.addDefaultClientUser();
        } else {
          this.clientUserData.email_body = this.emailBody;
          this.saveClientUser();
        }
      }
    },
    closeEmailDialog() {
      this.emailDialog = false;
      if(this.emailTemplate === 'client_user_welcome' && this.addingDefaultUser) {
        this.portalAccessCheck = false;
        this.$nextTick(() => {
          this.clientData.client_portal_access = false;
        });
      }
    },
    resetPassword(clientUserId) {
      this.service.reset_pwd(this.clientData.prim_uid, clientUserId).then((res) => {
        if (res.success) {
          this.resetPwdEmail = res.email;
          this.passwordDialog = true
        }
      })
    },
    loginAsClientUser(clientUserId) {
      this.service.get_user_login_token(this.clientData.prim_uid, clientUserId).then((res) => {
        if (res.data.success) {
          window.open(res.data.login_as_url, '_blank');
        }
      })
    },
    closePasswordDialog() {
      this.passwordDialog = false
      this.resetPwdEmail = '';
    },
    submit() {
      this.showDialog = false
    },
    goToDStep2() {
      this.dStep2 = true
    },
    saveChip() {
      const {chips, currentInput, set} = this;
      ((set && chips.indexOf(currentInput) === -1) || !set) && chips.push(currentInput);
      this.currentInput = '';

    },
    getInitials(chip) {
      let matches = chip.match(/\b(\w)/g);
      return matches.join('')
    },
    deleteChip(index) {
      this.chips.splice(index, 1);
    },
    backspaceDelete({which}) {
      which == 8 && this.currentInput === '' && this.chips.splice(this.chips.length - 1);
    },
    handleModalClose() {
      this.showDialog = false;
    },
    formatTaxNumber(tax_number) {
      if (this.clientData.private.tax_number) {
        this.clientData.private.tax_number = String(tax_number).substring(0, 13).replace(/[A-Z]/gi, '')
      }
    },
    formatTaxNumberBusiness(tax_number) {
      if (this.clientData.business.tax_number) {
        this.clientData.business.tax_number = String(tax_number).substring(0, 13).replace(/[A-Z]/gi, '')
      }
    },
    cancelClientTypeChange() {
      this.showClientTypeChangeDialog = false;
      this.newClientType = '';
    },
    saveClientTypeChange() {
      this.showClientTypeChangeDialog = false;
      let postData = [];
      postData['clientType'] = this.newClientType;
      postData['prim_uid'] = this.clientData.prim_uid;
      this.service.update(postData).then((res) => {
        this.newClientType = '';
        if (res) {
          this.fetchClientData();
        }
      })
    },
    clientTypeChanged(e) {
      if (this.clientData.clientType && this.clientData.clientType !== '' && e.code !== this.clientData.clientType) {
        this.newClientType = e.code;
        this.showClientTypeChangeDialog = true;
        this.$nextTick(() => this.clientType = this.clientData.clientType);
      }
    },
    cancelReadonly() {
      if(this.clientData.freezed_declaration) {
        this.$router.push('/declaration/edit?uid=' + this.clientData.freezed_declaration);
      } else {
        this.$router.push('/client/list')
      }
    },
    handleHasLegalRepresentativeCheck(isCheck) {
      this.clientData.hasLegalRepresentativeChecked = !!isCheck;
    },
    handlePersonMenuClick(args) {
      switch (args.menuId) {
        case 'cm.add_person':
          this.resetAddPersonModalData();
          this.addPersonDialog = true;
          this.cityListForPerson = [];
          break;

        case 'cm.edit_person':
          this.resetEditPersonModalData();

          if (args.data[0].id) {
            this.editPerson(args.data[0].id);
          }
          break;

        case 'cm.delete_person':
          this.$modal.show({
            headerText: this.$t('general.grid.delete_record'),
            text: this.$t('general.grid.delete_confirm'),
            showClose: true,
            canEscape: true,
            onConfirm: () => {
              this.deleteData.uids = [];
              if (args.data.length > 0) {
                for (let i = 0; i < args.data.length; i++) {
                  this.deleteData.uids.push(args.data[i].id);
                }
              }

              this.deleteData.parentType = 'client';
              this.deleteData.parentId = this.clientUid;
              this.deleteData.action = 'delete';
              this.deleteData.client_uid = this.clientUid;
              this.service.savePersonModalData(this.deleteData, true).then((response) => {
                if (typeof response.success !== 'undefined' && response.success) {
                  this.triggerReload(this.personGridId);

                  this.resetAddPersonModalData();

                  this.$modal.visible = false;
                  this.setClientDataChanged(true);
                }
              });
            }
          });
          break;
      }
    },
    handlePersonColClick(e) {
      if (e.gridId === this.personGridId) {
        this.editPerson(e.row.id);
      }
    },
    editPerson(uid) {
      if (uid) {
        let getData = {
          client_uid: this.clientUid,
          uid: uid,
          parentType: 'client',
          parentId: this.clientUid,
        };

        this.service.getPersonModalData(getData, true).then((response) => {
          if (typeof response.success !== 'undefined' && response.success && response.data) {
            this.editPersonModalSaveData = response.data;
            this.editPersonDialog = true;
            this.cityListForPerson = [];
          }
        });
      }
    },
    resetAddPersonModalData() {
      this.addModalSaveData = {
        salutation: '',
        title: '',
        first_name: '',
        last_name: '',
        tax_number: '',
        identification_number: '',
        date_of_birth: '',
        status: '',
        street: '',
        house_number: '',
        zip: '',
        postbox: '',
        city: '',
        country: '',
        email: '',
        mobile: '',
        phone: '',
        fax: ''
      };

      this.addClientPersonFirstNameRequired = false;
      this.addClientPersonLastNameRequired = false;
    },
    addPersonModalData() {
      this.addClientPersonFirstNameRequired = true;
      this.addClientPersonLastNameRequired = true;

      if (this.isReadOnly) {
        return;
      }

      // Reset validation status
      for (let attr in this.personModalSaveErrors) {
        this.personModalSaveErrors[attr].invalid = false;
      }
      this.$v.$touch();

      let invalid = false;
      if (this.$v.$invalid) {
        for (let attr in this.personModalSaveErrors) {
          if (typeof this.$v.addModalSaveData[attr] !== 'undefined') {
            this.personModalSaveErrors[attr].invalid = this.$v.addModalSaveData[attr].$error;

            if (this.personModalSaveErrors[attr].invalid) {
              invalid = true;
            }
          }
        }
      }

      if (invalid) {
        return;
      }

      this.addModalSaveData.parentType = 'client';
      this.addModalSaveData.parentId = this.clientUid;
      this.addModalSaveData.action = 'add';
      this.addModalSaveData.client_uid = this.clientUid;

      this.service.savePersonModalData(this.addModalSaveData, true).then((response) => {
        if (typeof response.success !== 'undefined' && response.success) {

          this.addPersonDialog = false;
          this.triggerReload(this.personGridId);

          this.setClientDataChanged(true);
        }
      })
    },
    getDefaultEditPersonModal() {
      return structuredClone({
        salutation: '',
        title: '',
        first_name: '',
        last_name: '',
        tax_number: '',
        identification_number: '',
        date_of_birth: '',
        status: '',
        street: '',
        house_number: '',
        zip: '',
        postbox: '',
        city: '',
        country: '',
        email: '',
        mobile: '',
        phone: '',
        fax: ''
      });
    },
    resetEditPersonModalData() {
      this.editPersonModalSaveData = this.getDefaultEditPersonModal();
    },
    editPersonModalData() {
      if (this.isReadOnly) {
        return;
      }

      // Reset validation status
      for (let attr in this.personModalSaveErrors) {
        this.personModalSaveErrors[attr].invalid = false;
      }
      this.$v.$touch();

      let invalid = false;

      if (this.$v.$invalid) {
        for (let attr in this.personModalSaveErrors) {
          if (typeof this.$v.editPersonModalSaveData[attr] !== 'undefined') {
            this.personModalSaveErrors[attr].invalid = this.$v.editPersonModalSaveData[attr].$error;

            if (this.personModalSaveErrors[attr].invalid) {
              invalid = true;
            }
          }
        }
      }

      if (invalid) {
        return;
      }

      this.editPersonModalSaveData.parentType = 'client';
      this.editPersonModalSaveData.parentId = this.clientUid;
      this.editPersonModalSaveData.action = 'edit';
      this.editPersonModalSaveData.client_uid = this.clientUid;

      this.service.savePersonModalData(this.editPersonModalSaveData, true).then((response) => {
        if (typeof response.success !== 'undefined' && response.success) {

          this.editPersonDialog = false;
          this.triggerReload(this.personGridId);
        }
      })
    },
    searchZipForPersonForAdd() {
      this.zipSearchForPerson = true;
      if (this.addModalSaveData.city === '' && this.addModalSaveData.zip !== '') {
        this.cityListForPerson = [];
        this.$api.lookup_city(this.addModalSaveData.zip).then((cities) => {
          this.cityListForPerson = cities;
          if (cities.length === 1) {
            this.addModalSaveData.city = cities[0].code;
          }
          this.zipSearchForPerson = false;
        });
      }
    },
    searchZipForPersonForEdit() {
      this.zipSearchForPerson = true;
      if (this.editPersonModalSaveData.city === '' && this.editPersonModalSaveData.zip !== '') {
        this.cityListForPerson = [];
        this.$api.lookup_city(this.editPersonModalSaveData.zip).then((cities) => {
          this.cityListForPerson = cities;
          if (cities.length === 1) {
            this.editPersonModalSaveData.city = cities[0].code;
          }
          this.zipSearchForPerson = false;
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
}

.label-white {
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: white;
  text-align: left;
}

html, body {
  background-color: #fff;
  height: 100%;
}

.button-label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}

.checkbox {
  height: 37px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  border-radius: 2px;
  background-color: #ffffff;

  &.checked {
    color: black;
    text-decoration: line-through
  }

}

.chip-container {

  border: 2px solid #7a7a7a;
  min-height: 34px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  .chip {
    margin: 4px;

    padding-right: 5px;
    display: flex;
    align-items: center;


    height: 22px;
    border-radius: 15px;
    background-color: #f2f2f2;
    box-sizing: border-box;
    font-family: 'Arial Regular', 'Arial', sans-serif;
    color: #333333;
    text-align: center;
    line-height: normal;

    img {
      cursor: pointer;
      margin-left: 8px;
      font-size: 12px;

      width: 8px;
      height: 8px;
      box-sizing: border-box;
      font-family: 'Arial Regular', 'Arial', sans-serif;
      color: #333333;
      text-align: center;
      line-height: normal;
    }
  }

  .chip-logo {

    height: 13px;
    width: 13px;
    @include segoe-semi-bold;
    font-size: 8px;
    color: #ffffff;
    text-align: center;
    padding-bottom: 2px;
  }

  .chip-text {


    @include segoe-regular;
    color: #7b7c7c;
    font-size: 12px;

  }

  input {
    flex: 1 1 auto;
    width: 30px;
    border: none;
    outline: none;
    padding: 4px;
  }
}

//properties
th {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;

  //&:hover {
  //  //.icontable {
  //  //  visibility: visible;
  //  //}
  //
  //}

  //.icontable {
  //  visibility: collapse;
  //}
}

.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.title {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.mand {
  height: 32px;
  padding: 2px 2px 2px 2px;
  background-color: #f4f1f3;
  box-sizing: border-box;

  &__text {

    font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
    color: #aeaeae;
    text-align: left;
    font-size: 15px;
    margin-left: 4px;
  }
}

.box {
  height: auto;
  padding: 10px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 30px !important;
  display: table;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 14px;
}

//.text {
//  background-color: rgba(255, 255, 255, 0);
//  box-sizing: border-box;
//  font-family:  'Segoe UI', sans-serif;
//  color: #333333;
//  text-align: left;
//  line-height: 20px;
//  font-size: 15px;
//  margin-bottom: 15px;
//}

input[type=radio] {
  border: 0;
  width: 2em;
  height: 25px;
}

.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}

.inventory {
  width: 16px;
  height: 20px;

}

.label-link {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  text-decoration: underline;
  color: #229d56;
  text-align: left;
  line-height: normal;
  font-size: 15px;
}

.rect {

  height: auto;
  padding: 10px;
  border: 2px solid #7a7a7a;
  background-color: #ffffff;
  box-sizing: border-box;
  padding-left: 5px;
}

.textbold {

  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 15px;
}

.password-text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 25px;
  font-size: 18px;
}

.client_user_id_container {
  position: relative;
  width: 367px;

  .label-disabled {
    padding: 5px;
    background-color: #f4f1f3;
    box-sizing: border-box;
    color: #aeaeae;
    text-align: left;
  }

  i.pi-spinner {
    position: absolute;
    right: 8px;
    top: 10px;
  }
}

.portal_access_help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.box {
  margin-bottom: 10px;
  display: flex;
}

.client-user-grid::v-deep {
  .grid_action_icon {
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .reset_password_icon {
    background-image: url('~@/assets/key_black.svg');
  }

  .request_property_icon {
    background-image: url('~@/assets/property_add_black.svg');
  }

  .login_as_icon {
    background-image: url('~@/assets/globe.svg');
  }
}

.header {
  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 18px;
}
.auto-width {
  width: auto !important;
}
.datev-client-search-loading {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .pi-spinner {
    font-size: 30px;
    color: $primary;
  }
}
.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}
.datev-client-search-result {
  height: 350px;
  overflow-y: auto;
}

.sub-title-label {
  font-weight: 700;
}
</style>
