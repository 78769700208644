<template>
  <div>
    <div class="mb-8 mt-8">
      <div class="flex justify-around space-x-1">
        <div class="w-full text-left flex">
          <label class="w-28">{{ $t('components.change_management_data_query.process.steps.process_type') }}:</label>
          <label class="ml-5">{{ processType }}</label>
        </div>
      </div>
      <Fieldset legend="Filter" class="filter-fieldset" v-if="showFilter">
        <div class="flex justify-around space-x-4">
          <InputSelect class="mt-2 w-full"
                       :label="$t('components.change_management_data_query.process.assignedUser')"
                       :options="userList"
                       v-model="filters.assignee"
                       :selectedValue="filters.assigne"
                       :filterable="true"
                       :show-clear="true"
                       :placeholder="$t('general.please_select')"
                       @on-item-selected="fetchClientList"
          />
          <InputSelect class="mt-2 w-full"
                       :label="$t('components.change_management_data_query.process.client')"
                       v-model="filters.clientId"
                       :selectedValue="filters.clientId"
                       :options="clientList"
                       :loading="loadingClient"
                       :search-fn="clientListSearch"
                       :total-record-count="totalClientsCount"
                       :filterable="true"
                       :sort="true"
                       :show-clear="true"
                       :placeholder="$t('general.client_select')"
                       @on-item-selected="fetchProperties"
          />
        </div>
      </Fieldset>
      <div class="flex justify-around space-x-1 mt-5">
        <DualListSelect
            :list="filteredPropertiesList"
            ref="propertyDualList"
            v-model="selectedProperties"
            :columns="propertyDualListColumns"
            dataKey="id"
            sort-attr="name"
            :enable-all-select="true"
            :leftHeader="$t('components.change_management_data_query.process.properties')"
            :rightHeader="$t('components.change_management_data_query.process.properties')"
            :countShow="true"
            :validator="validator"
            @validatorFail="handleValidatorFail"
            @validatorFailAll="handleAllValidatorFails"
        />
      </div>
        <div class="flex flex-start space-x-4">
        <InputSelect class="mt-2"
                     :label="$t('components.change_management_data_query.process.change_year')"
                     v-model="selectedYear"
                     :selectedValue="selectedYear"
                     :options="yearList"
        />
        </div>
    </div>
    <div class="footer">
      <div class="flex justify-end  space-x-4 mr-6">
        <Button class="w-56" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
        <Button class="w-56" :text="$t('buttons.next')" @click="submitNext" :disabled="selectedYear==''"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import DualListSelect from "@/components/labels/DualListSelect";
import {PropertyService} from "@/services/property.service";
import {UserService} from "@/services/user.service";
import Fieldset from "primevue/fieldset";
import {ClientService} from "@/services/client.service";

export default {
  name: "Step1",
  components: {DualListSelect, Fieldset},
  props: {
    active: {
      type: Boolean,
      default: false
    },
    fromGrid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clientService: new ClientService(),
      userService: new UserService(),
      propertyService: new PropertyService(),
      currentProcessType: '',
      propertyList: [],
      selectedProperties: [],
      selectedYear: '',
      yearList: [],
      propertyDualListColumns: [
        {
          field: 'propertyId',
          label: this.$t('components.change_management_data_query.grid.id'),
          search: true
        }, {
          field: 'name',
          label: this.$t('components.change_management_data_query.grid.property'),
          search: true
        }
      ],
      usersCanRelease: [],
      loadingClient: false,
      userList: [],
      clientList: [],
      totalClientsCount: 0,
      showFilter: false,
      filters: {
        assignee: null,
        clientId: null
      }
    }
  },
  computed: {
    processType() {
      return this.$t('components.change_management_data_query.process.title');
    },
    processTypeVal() {
      return this.getSteps().processType ? this.getSteps().processType : '';
    },
    filteredPropertiesList() {
      let filteredProperties = this.propertyList;
      return filteredProperties;
    }
  },
  watch: {
    processType() {
      if (this.active && this.processType && this.processType !== this.currentProcessType) {
        let preselectedProperties = this.$route.params.selectedProperties
          && this.$route.params.selectedProperties.length > 0
          && this.getSteps().processType !== 'request_internal_approval';

        if (!preselectedProperties) {
          this.fetchProperties();
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.selectedProperties && this.$route.params.selectedProperties.length > 0) {
      this.selectedProperties =  this.$route.params.selectedProperties;
      this.showFilter = false;
    } else {
      this.showFilter = true;
      this.clientService.listAll().then((clients) => {
        if (clients && clients.list) {
          this.totalClientsCount = clients.totalRecordCount;
          this.clientList = clients.list.map((list) => {
            return {
              name: list.client_id + ' - ' + list.client_name,
              code: list.prim_uid
            };
          });
        }
      });
    }

    this.userService.listAll().then((list) => {
      this.usersCanRelease = list.filter((o) => {
        return o.permissions.release_declaration && o.permissions.release_declaration === '1';
      }).map((item) => {
        return {
          name: item.name,
          code: item.id
        };
      });

      if (this.showFilter) {
        this.userList = list.filter((o) => {
          return o.statusKey === 'active' && (o.roleKey === 'administrator' || o.roleKey === 'user');
        }).map((item) => {
          return {
            name: item.name,
            code: item.id
          };
        });
      }
    });

    this.yearList = this.getOptionsYear();
  },
  methods: {
    ...mapGetters('data_query_bulk_add', ['getSteps']),
    ...mapMutations('data_query_bulk_add', ['saveSteps']),
    ...mapMutations('grid', ['triggerReload']),
    submitNext() {
      if (this.selectedProperties.length > 0) {
        this.saveSteps({
          selectedProperties: this.selectedProperties,
          clientId: this.filters.clientId,
          selectedYear: this.selectedYear
        });

        this.$emit('goToNextStep');
      }
    },
    clientListSearch(params) {
      return this.clientService.listAll(params).then((clients) => {
        if (clients && clients.list) {
          return clients.list.map((list) => {
            return {
              name: list.client_id + ' - ' + list.client_name,
              code: list.prim_uid
            };
          });
        } else {
          return false;
        }
      });
    },
    fetchClientList() {
      this.loadingClient = true;
      if (typeof this.filters.assignee !== 'undefined' && this.filters.assignee > 0) {
        this.clientService.listUserClient(this.filters.assignee).then((clients) => {
          if (clients && clients.list) {
            this.totalClientsCount = clients.totalRecordCount;
            this.clientList = clients.list.map((list) => {
              return {
                name: list.client_id + ' - ' + list.client_name,
                code: list.prim_uid
              };
            });

            this.selectedProperties = [];
            this.propertyList = [];
            this.loadingClient = false;
          }
        });
        this.loadingClient = false;
      }
    },
    fetchProperties(item) {
      if (parseInt(item.code) === 0) {
        return;
      }

      this.selectedProperties = [];
      let clientId = parseInt(item.code);
      let params = {
        always : 'join_last_declaration'
      };
      this.propertyService.listClientProperties(clientId, params).then((response) => {
        if (response.totalRecordCount > 0) {
          this.propertyList = response.list.map((o) => {
            return {
              id: o.prim_uid,
              propertyId: o.property_id,
              name: o.name
            };
          });
        } else if (this.getSteps().processType !== 'request_internal_approval') {
          this.propertyList = [];

          this.$api.showToast(this.$t('components.change_management_data_query.process.no_record_found'), 'error');
        }
      });
    },
    validator(item) {
      return !(item.elsterStatus === '2' && this.processTypeVal === 'request_client_approval');
    },
    handleAllValidatorFails(items) {
      if (this.processTypeVal === 'request_client_approval') {
        this.$api.showToast(
          this.$t('components.change_management_data_query.process.invalid_elster_status_all') + ': ' + items.map(items => items.propertyId).join(', '),
          'error'
        );
      }
    },
    handleValidatorFail(item) {
      if (this.processTypeVal === 'request_client_approval') {
        this.$api.showToast(
          this.$t('components.change_management_data_query.process.invalid_elster_status') + ': ' + item['propertyId'],
          'error'
        );
      }
    },
    getOptionsYear() {
      let OptionsYear = [];
      let year = 2022;
      var current = (new Date()).getFullYear();

      for (var i = 0; i < (current - year) + 1 ; i++) {
        OptionsYear.push({
          name: year + i,
          code: year + i
        });
      }

      return OptionsYear;
    }
  }
}
</script>

<style scoped lang="scss">
.link {
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
}
.filter-fieldset {
  margin-top: 15px;
  border: 1px solid #dfdfdf;

  ::v-deep .p-fieldset-legend {
    margin-left: 15px;
    padding: 0 10px 0 10px;
    border: 0;
    background: unset;
  }
}
</style>
