<template>
  <ModalDialog :headerText="$t('declarations.tax_calculation.select_community')"
               @close="closeCommunitySelection">
    <template v-slot:body>
      <div class="flex flex-col justify-start community_modal">
        <div class="flex justify-around mt-2 space-x-10">
          <div class="w-full mt-2 text-left">
            <p>{{ $t('declarations.tax_calculation.select_community_desc') }}</p>
            <p class="mt-2">{{ $t('declarations.tax_calculation.select_community_save_info') }}</p>
          </div>
        </div>
        <div class="flex justify-around mt-2 space-x-10">
          <div class="w-full flex flex-col justify-start mt-2">
            <InputCheck @check="changeSaveCalculationCommunity"
                        :text="$t('declarations.tax_calculation.select_community_save') "
            />
          </div>
        </div>
        <div class="flex justify-around mt-2 space-x-10">

          <div class="w-full flex flex-col justify-start mt-2">
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step2.community')"
                :options="communityList"
                v-model="selectedCommunity"
                :filterable="true"
                :auto-complete="false"
                :search-fn="communityListSearch"
                :total-record-count="12000"
                :selected-value="selectedCommunity"
                overlay-append-to="body"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
        <Button class="" :text="$t('buttons.ok')"
                @click="confirmHandler"/>
      </div>
    </template>
  </ModalDialog>
</template>

<script>
import {DeclarationService} from "@/services/declaration.service";
import ModalDialog from "@/components/ModalDialog";

export default {
  name: "CommunitySelector",
  components: {ModalDialog},
  data() {
    return {
      selectedCommunity: null,
      communityList: [],
      saveCalculationCommunity: false,
      service: new DeclarationService(),
    }
  },
  props: {
    context: {
      type: Object,
      default: () => {}
    },
    federalStateId: {
      type: Number,
      default: 0
    },
    declarationId: {
      type: Number,
      default: 0
    }
  },
  methods: {
    communityListSearch(search) {
      const params = {uid: this.declarationId, community: search.txtFilter}
      if (search.uid) {
        params.communityUid = search.uid;
      }

      return this.service.get_communities(params).then((data) => {
        if (data.error) {
          return false;
        }

        const communities = Object.keys(data.data.communities).map((key) => data.data.communities[key]);
        if (!communities) {
          return false;
        }

        return communities.map(({community, agn, land_value, rent_level}) => {
          if (this.federalStateId === 9 || this.federalStateId === 7) {
            community = community + " -  Ø Bodenrichtwert: " + land_value + " €/m²";
          } else {
            community = `${community}  -  Mietniveaustufe: ${rent_level}`;
          }

          return {
            name: community,
            code: agn
          };
        });
      });
    },
    closeCommunitySelection() {
      this.property = null;
      this.$emit("close",{});
    },
    closeTaxCalculationErrorDialog() {
      this.showTaxCalculationError = false;
    },
    openCommunitySelection() {
    },
    changeSaveCalculationCommunity(value) {
      this.saveCalculationCommunity = value;
      this.$emit('check', this.saveCalculationCommunity);
    },
    confirmHandler() {
      this.$emit(
        'close',
        {
          communityId: this.selectedCommunity,
          declarationId: this.declarationId,
          context: this.context,
        }
      );
    }
  }
}
</script>

<style scoped>
.community_modal {
  height: 400px;
}
</style>
