import Vue from "vue";

// Inputs
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
//labels
import IconLabel from "@/components/labels/IconLabel";
import Tabs from "@/components/labels/Tabs";
//items
import MessageItem from "@/components/Dashboard/Items/MessageItem";
import NotificationItem from "@/components/Dashboard/Items/NotificationItem";
//dashboard
import Sidebar from "@/components/Dashboard/Sidebar";
import Stats from "@/components/Dashboard/Stats";
import Table from "@/components/Dashboard/Table";
import Header from "@/components/Dashboard/Header";

//register
import Step1 from "@/components/Register/Step1";
import Step2 from "@/components/Register/Step2";
import Step3 from "@/components/Register/Step3";
import Step4 from "@/components/Register/Step4";
import Finish from "@/components/Register/Finish";
import FormSteps from "@/components/FormSteps";
import Dashboard from "@/components/Dashboard/Dashboard";
//user management
import UserManagement from "@/components/user/UserManagement";
import UserMenu from "@/components/user/menus/UserMenu";
import UserTable from "@/components/user/UserTable";
import ResetPassword from "@/components/user/ResetPassword";
import Modal from "@/components/Modal";
import AddUser from "@/components/user/AddUser";
import EditUser from "@/components/user/EditUser";
import LogProtocol from "@/components/settings/LogProtocol";
import Branches from "@/components/settings/Branches";
import Departments from "@/components/settings/Departments";
import FilterInput from "@/components/inputs/FilterInput";
import AccountSettings from "@/components/settings/AccountSettings";
//Clients
import Clients from "@/components/clients/Clients";
import CreateNewClient from "@/components/clients/CreateNewClient";
import EditClient from "@/components/clients/EditClient";
import InputTextView from "../../components/inputs/InputTextView";
import InputRadio from "../../components/inputs/InputRadio";
import Properties from "../../components/properties/Properties";
import AddProperty from "../../components/properties/AddProperty";
import EditProperty from "../../components/properties/EditProperty";
import InputDate from "../../components/inputs/InputDate";
import Reports from "../../components/reports/Reports";
import ImportData from "../../components/datev/ImportData";
import ClientImportStatus from "../../components/clients/ImportStatus";
import ImportStatus from "../../components/datev/ImportStatus";
import AddisonImport from "../../components/addison/AddisonImport";
import AddisonGgw from "../../components/addison/AddisonGgw";
import LexwareImport from "../../components/addison/LexwareImport";
import Dms from "../../components/datev/Dms";
import Ggw from "../../components/datev/Ggw";
import CreateReport from "../../components/reports/CreateReport";
import ViewReport from "../../components/reports/ViewReport";
import DownloadReport from "../../components/reports/DownloadReport";
import DownloadDocuments from "../../components/clients/DownloadDocuments";
import ArrowProgress from "../../components/declarations/ArrowProgress";
import Draft from "../../components/declarations/Draft";
import Declarations from "../../components/declarations/Declarations";
import AddDeclaration from "../../components/declarations/AddDeclaration";
import DeclarationDetails from "../../components/declarations/DeclarationDetails";
import MainLayout from "../../layouts/MainLayout";
import System from "../../components/settings/System";
import DeclarationItem from "../../components/declarations/items/DeclarationItem";
import ClientRequested from "@/components/declarations/ClientRequested";
import Breadchrumbs from "../../components/Breadchrumbs";
import Billing from "../../components/settings/Billing";
import BillingTrial from "../../components/settings/BillingTrial";
import BillingSubscribe from "../../components/settings/BillingSubscribe";
import ConnectGetMyInvoices from "@/components/settings/ConnectGmi";

import ViewDocument from "../../components/declarations/ViewDocument";
import ModalDialog from "../../components/ModalDialog";
import ModalDialogRight from "../../components/ModalDialogRight";
import ModalDialogTable from "../../components/ModalDialogTable";
import AddClient from "../../components/clients/AddClient";
import CSVImport from "../../components/clients/CSVImport";
import csvimportproperty from "../../components/properties/CSVImport";
import BdoGrizzlyImport from "../../components/properties/BdoGrizzlyImport.vue";
import InputUpload from "../../components/inputs/InputUpload";
import Mannual from "../../components/clients/Mannual";
import DATEVImport from "../../components/clients/DATEVImport";
import FileItem from "../../components/search/FileItem";
import ClientItem from "../../components/search/ClientItem";
import PropertyItem from "../../components/search/PropertyItem";
import TaxItem from "../../components/search/TaxItem";
import NewsItem from "../../components/search/NewsItem";
import CSVimportieren from "../../components/clients/CSVimportieren";
import csvimportierenproperty from "../../components/properties/CSVimportieren";
import Mandant from "../../components/properties/Mandant";
import FinishProperty from "../../components/properties/Finish";
import InputCheck from "../../components/inputs/InputCheck";
import AddNewProperty from "../../components/properties/AddNewProperty";
import ArchivedProperties from "../../components/properties/ArchivedProperties";
import DataRequested from "../../components/declarations/DataRequested";
import Tenant from "../../components/declarations/Tenant";
import ELSTERTransmission from "../../components/declarations/ELSTERTransmission";
import ELSTERComplete from "../../components/declarations/ELSTERComplete";
import NotificationReceived from "../../components/declarations/NotificationReceived";
import Contradiction from "../../components/declarations/Contradiction";
import MassAppeal from "../../components/declarations/MassAppeal";
import Completed from "../../components/declarations/Completed";
import Archived from "../../components/declarations/Archived";
import Button from "../../components/inputs/Button";
import DualListSelect from "../../components/labels/DualListSelect";
import PageHeader from "../../components/PageHeader";
import Mailbook from "../../components/settings/Mailbook";
import Release from "../../components/declarations/Release";
import ReleaseRequest from "../../components/declarations/ReleaseRequest";
import EmptyList from "../../components/EmptyList";
import BulkTransfer from "../../components/declarations/BulkTransfer";
import BulkAdd from "../../components/declarations/BulkAdd";
import BulkAddStatus from "../../components/declarations/BulkAddStatus";
import PendingElster from "@/components/declarations/PendingElster";
import ButtonWithOption from "@/components/inputs/ButtonWithOption";
import BulkAddQuery from "../../components/changemanagement/BulkAddQuery";

// powers of attorney
import PowersOfAttorney from "@/components/settings/PowersOfAttorney";
import AddPowerOfAttorney from "@/components/settings/AddPowerOfAttorney";
import EditPowerOfAttorney from "@/components/settings/EditPowerOfAttorney";

// communications
import Communications from "@/components/settings/Communications";

// widget
import TransferStatus from "@/components/widgets/TransferStatus";

export default class GlobalComponents {
    static install() {


        const inputs = [InputText, InputSelect, FilterInput, InputTextView, InputRadio, InputDate, InputUpload, InputCheck, Button, ButtonWithOption
        ];
        const labels = [IconLabel, Tabs, DualListSelect];
        const items = [MessageItem, NotificationItem, DeclarationItem]
        const dahsboard = [Dashboard, Sidebar, Stats, Table, Header]
        const register = [Step1, Step2, Step3, Step4, Finish, FormSteps, ResetPassword]

        const user = [UserManagement, UserMenu, UserTable, ResetPassword, Modal, AddUser, EditUser]
        const settings = [LogProtocol, AccountSettings, System, Billing, BillingTrial, BillingSubscribe, Branches, Mailbook, Departments, ConnectGetMyInvoices]
        const clients = [Clients, CreateNewClient, EditClient, AddClient, CSVImport, Mannual, DATEVImport, CSVimportieren, ClientImportStatus, DownloadDocuments]
        const properties = [Properties, AddProperty, AddNewProperty, EditProperty, csvimportierenproperty, csvimportproperty, BdoGrizzlyImport, FinishProperty, Mandant, ArchivedProperties]
        const reports = [Reports, CreateReport, ViewReport, DownloadReport]
        const addison = [AddisonImport, LexwareImport, AddisonGgw]
        const datevs = [ImportData, Dms, Ggw, ImportStatus]
        const declarations = [Declarations, AddDeclaration, DeclarationDetails, ArrowProgress, Draft, ViewDocument, DataRequested, Tenant, ELSTERTransmission, ELSTERComplete, NotificationReceived, Contradiction, MassAppeal, Completed, Archived, Release, ReleaseRequest, BulkTransfer, BulkAdd, BulkAddStatus, PendingElster, ClientRequested, BulkAddQuery]
        const layouts = [MainLayout]
        const menus = [Breadchrumbs]
        const modals = [ModalDialog, ModalDialogRight, ModalDialogTable]
        const search = [FileItem, ClientItem, PropertyItem, TaxItem, NewsItem]
        const common = [PageHeader, EmptyList]
        const powersofattorney = [PowersOfAttorney, AddPowerOfAttorney, EditPowerOfAttorney]
        const communications = [Communications]
        const widgets = [TransferStatus]

        const components = [
            ...inputs,
            ...labels,
            ...items,
            ...dahsboard,
            ...register,
            ...user,
            ...settings,
            ...clients,
            ...properties,
            ...reports,
            ...datevs,
            ...addison,
            ...declarations,
            ...layouts,
            ...menus,
            ...modals,
            ...search,
            ...common,
            ...powersofattorney,
            ...communications,
            ...widgets
        ];

        components.forEach((comp) => {
            Vue.component(comp.name, comp);
        });
    }
}
